<template>
  <div>
    <b-row id="linhaIndiceSelecionado">
      <b-col sm="12" md="3">
        <b-card border-variant="warning" class="position-relative">
          <b-card-title>Índice Selecionado</b-card-title>
          <b-card-text> {{ indiceSelecionado }} </b-card-text>
          <feather-icon icon="BarChart2Icon" size="64" class="icon-bottom-right"></feather-icon>
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card border-variant="primary">
          <b-card-title>Competência</b-card-title>
          <b-card-text> {{ indiceCompetencia }} </b-card-text>
          <feather-icon icon="CalendarIcon" size="64" class="icon-bottom-right"></feather-icon>
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card border-variant="success">
          <b-card-title>Taxa Acumulada</b-card-title>
          <b-card-text> {{ indiceTaxaAcumulada }} </b-card-text>
          <feather-icon icon="PercentIcon" size="64" class="icon-bottom-right"></feather-icon>
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card border-variant="danger">
          <b-card-title>Taxa Mensal</b-card-title>
          <b-card-text> {{ indiceTaxaMensal }} </b-card-text>
          <feather-icon icon="PercentIcon" size="64" class="icon-bottom-right"></feather-icon>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5">
        <b-card>
          <b-card-title>
            Configuração e Importação
            <feather-icon id="iconInfoDocs" icon="InfoIcon" style="margin-bottom: 3px" />
            <b-tooltip placement="bottom" target="iconInfoDocs" noninteractive
              >Selecione o índice de atualização que irá compor a taxa mensal.</b-tooltip
            >
          </b-card-title>
          <!-- form -->
          <b-row>
            <!-- Tipo de índice  -->
            <b-col>
              <b-form-group label="Índice de atualização" label-for="tipoIndice">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefCriarNovo"
                  name="tipoIndice"
                  id="tipoIndice"
                  v-model="dados.tipoIndice"
                  :options="indiceOpcoesSalvarIndice"
                  :reduce="(option) => option.value"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <!-- Salvar tipo de índice -->
            <b-col md="auto">
              <b-button
                :disabled="!acesso.ConfiguracoesSistemaIndiceRefCriarNovo"
                id="salvarIndice"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="primary"
                class="mt-2"
                @click="verificaIndice"
              >
                Salvar
              </b-button>
              <b-tooltip placement="bottom" target="salvarIndice" noninteractive
                >Alterar índice que compõe a taxa mensal.</b-tooltip
              >
            </b-col>

            <!-- Importar últimos 12 meses do índice selecionado -->
            <b-col md="auto">
              <b-button
                id="buttonImportarIndice"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="secondary"
                class="mt-2"
                @click="verificaImportacao"
                :disabled="!acesso.ConfiguracoesSistemaIndiceRefCriarNovo"
              >
                Importar Índice
              </b-button>
              <b-tooltip placement="bottom" target="buttonImportarIndice" noninteractive>Importar índices do IBGE</b-tooltip>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="7">
        <b-card title="Pesquisa">
          <b-row>
            <!-- Competência inicial de pesquisa -->
            <b-col md="auto">
              <b-form-group label="Tipo de Índice" label-for="dataInicial">
                <b-form-select
                  id="dataInicial"
                  class="form-control"
                  type="text"
                  placeholder="MM/AAAA"
                  style="width: 160px"
                  v-model="pesquisar.tipoIndice"
                  :options="indiceOpcoesPesquisar"
                  :reduce="(option) => option.value"
                  v-on:keydown.enter="goPesquisar()"
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefConsultar"
                />
              </b-form-group>
            </b-col>

            <!-- Competência inicial de pesquisa -->
            <b-col md="auto">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <cleave
                  id="dataInicial"
                  class="form-control"
                  type="text"
                  placeholder="MM/AAAA"
                  v-model="pesquisar.dataInicial"
                  :options="cleaveCompetencia"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefConsultar"
                />
              </b-form-group>
            </b-col>

            <!-- Competência final de pesquisa -->
            <b-col md="auto">
              <b-form-group label="Data Final" label-for="dataFinal">
                <cleave
                  id="dataFinal"
                  class="form-control"
                  type="text"
                  placeholder="MM/AAAA"
                  v-model="pesquisar.dataFinal"
                  :options="cleaveCompetencia"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefConsultar"
                />
              </b-form-group>
            </b-col>

            <!-- Pesquisar -->
            <b-col md="auto">
              <b-button
                :disabled="isBusy || !acesso.ConfiguracoesSistemaIndiceRefConsultar"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="primary"
                class="mt-2"
                @click.prevent="goPesquisar()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-show="items.length > 0">
      <b-row>
        <b-col cols="12">
          <!-- Tabela -->
          <b-table
            v-show="items.length > 0"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(taxa)="row">{{ row.item.taxa }}%</template>
            <template #cell(taxaAcumulado)="row">{{ row.item.taxaAcumulado }}%</template>
          </b-table>

          <!-- Paginação -->
          <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              style="align-items: center"
              label="Por Página"
              label-cols="5"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" class="ml-2" />
            </b-form-group>

            <!-- pagination -->
            <b-pagination
              first-number
              last-number
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <!-- Opções de Índice de Importação -->
    <b-modal
      id="modal-opcoes-importacao"
      ref="modal-opcoes-importacao"
      centered
      size="sm"
      title="Selecione o índice para importar"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="formIndiceImportacao">
        <b-form-group label="Índices" label-for="indices">
          <validation-provider #default="{ errors }" name="Ano de Competência" rules="required">
            <v-select-pt
              id="indices"
              name="indices"
              v-model="indiceSelecionadoImportacao"
              :options="indicesOpcoesImportacao"
              :reduce="(option) => option.value"
              label="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          :disabled="isBusyImportacao"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          class="mt-2"
          @click="realizarImportacao"
        >
          {{ !isBusyImportacao ? 'Importar' : '' }}
          <b-spinner small v-if="isBusyImportacao" />
        </b-button>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import { isLetter, msgError, msgSuccess, formatarData, formatarCompetenciaDescricao } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
      Cleave,
      msgError,
      msgSuccess,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dadosInstitutoSelecionado: {},
        isBusy: false,
        isBusyImportacao: false,

        // Cards de Índice Selecionado
        indiceSelecionado: '',
        indiceCompetencia: '',
        indiceTaxaAcumulada: '',
        indiceTaxaMensal: '',

        //Tabela e Paginação
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 10,
        currentPage: 1,
        fields: [
          {
            key: 'ano',
            sortable: true,
          },
          {
            key: 'mes',
            label: 'Mês',
            sortable: true,
          },
          {
            key: 'tipoIndice',
            label: 'Tipo Índice',
            sortable: true,
          },
          {
            key: 'taxa',
            sortable: true,
          },
          {
            key: 'taxaAcumulado',
            sortable: true,
          },
          {
            key: 'updatedAt',
            label: 'Data de Atualização do Índice',
            formatter: (value) => {
              return value ? formatarData(value) : ''
            },
            sortable: true,
          },
        ],
        totalRows: 0,
        items: [],
        pesquisar: {
          tipoIndice: '',
        },
        pageOptions: [10, 25, 50, 100],

        indiceSelecionadoImportacao: '',
        indicesOpcoesImportacao: [
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
        ],
        number: {},
        dados: {
          tipoIndice: '',
        },
        modoAutenticacao: '',
        isLetter,
        cleaveCompetencia: {
          blocks: [2, 4],
          delimiter: '/',
          numericOnly: true,
        },
        indiceOpcoesSalvarIndice: [
          { value: '', text: 'Selecione' },
          { value: 'ipca', text: 'IPCA' },
          { value: 'inpc', text: 'INPC' },
          { value: 'igpm', text: 'IGPM' },
        ],
        indiceOpcoesPesquisar: [
          { value: '', text: 'Selecione' },
          { value: 'ipca', text: 'IPCA' },
          { value: 'inpc', text: 'INPC' },
        ],
        acesso: {
          ConfiguracoesSistemaIndiceRefConsultar: null,
          ConfiguracoesSistemaIndiceRefCriarNovo: null,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = true
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = true
      } else {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefConsultar
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefCriarNovo
      }

      this.dadosInstitutoSelecionado = this.userData.Instituto

      // Seta os dados do índice selecionado de acordo com o instituto selecionado
      this.buscaDadosIndiceSelecionado()
      // this.indiceSelecionado = 'IPCA'
      // this.indiceCompetencia = 'Janeiro/2024'
      // this.indiceTaxaAcumulada = '0,07%'
      // this.indiceTaxaMensal = '-3,31%'

      // Seta o tipo do índice para "Selecionar" e limpa a validação de vazio
      this.dados.tipoIndice = ''

      // Seta o tipo do índice de acordo com o instituto selecionado
      this.dados.tipoIndice = this.dadosInstitutoSelecionado.tipoIndiceSelecionado?.toLowerCase()
      this.pesquisar.tipoIndice = this.dados.tipoIndice
    },
    methods: {
      listaDeIgpm(indices) {
        let taxas = {}
        let acumulados = {}

        indices.forEach((item) => {
          const competencia = item.competencia
          const ano = competencia.substring(2) // Pega o ano (últimos 4 caracteres)
          const mes = competencia.substring(0, 2) // Pega o mês (primeiros 2 caracteres)
          const competenciaFormatada = ano + mes // Formata como "AAAAMM"

          // Divida os valores por 100 e formate com duas casas decimais
          taxas[competenciaFormatada] = (parseFloat(item.taxaMensal) / 100).toFixed(2)
          acumulados[competenciaFormatada] = (parseFloat(item.taxaAcumulada) / 100).toFixed(2)
        })

        const param = {
          acumulados: acumulados,
          taxas: taxas,
          tipoIndice: 'IGPM',
        }

        useJwt
          .post('cadastro/instituto/SetImportarTaxas', param)
          .then((response) => {
            if (response) {
              this.$message.success('Importação realizada com sucesso!')
            } else {
              this.$message.error('Falha na importação!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Ocorreu um erro ao realizar a importação!')
          })
      },
      modalIGPM(estadoDoModal) {
        if (estadoDoModal === 'aberto') {
          this.$refs['modal-indice-igpm'].show()
        } else if (estadoDoModal === 'fechado') {
          this.$refs['modal-indice-igpm'].hide()
        }
      },
      goPesquisar() {
        this.isBusy = true
        let mesCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(0, 2))
        let anoCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(3, 7))
        let mesCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(0, 2))
        let anoCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(3, 7))

        let filtroValidado = this.validaFiltros(
          mesCompetenciaInicial,
          anoCompetenciaInicial,
          mesCompetenciaFinal,
          anoCompetenciaFinal,
        )

        let param = {
          mesCompetenciaInicial: mesCompetenciaInicial,
          anoCompetenciaInicial: anoCompetenciaInicial,
          mesCompetenciaFinal: mesCompetenciaFinal,
          anoCompetenciaFinal: anoCompetenciaFinal,
          tipoIndice: this.pesquisar.tipoIndice,
        }
        if (!this.pesquisar.tipoIndice) delete param.tipoIndice

        if (filtroValidado) {
          useJwt
            .post('cadastro/instituto/GetTaxas', param)
            .then((response) => {
              if (response.data.length > 0) {
                this.items = response.data
                this.totalRows = response.data.length
              } else this.$message.error('Não foram encontrados nenhum índice!')
              this.isBusy = false
            })
            .catch((error) => {
              console.error(error)
              this.isBusy = false
            })
        } else {
          this.$message.error('O filtro para pesquisa está incorreto!')
          this.isBusy = false
        }
      },
      verificaIndice() {
        if (this.dados.tipoIndice) {
          this.modoAutenticacao = 'salvarIndice'
          this.$refs['modal-auth-senha'].show()
        } else this.$message.error('Favor selecionar o Índice de atualização')
      },
      salvarIndice() {
        let param = {
          institutoId: this.userData.institutoSelecionado,
          tipoIndice: this.dados.tipoIndice,
        }

        useJwt.post('cadastro/instituto/SetInstitutoIndice', param).then((res) => {
          this.$message.success('Índice alterado com sucesso, recarregue o sistema para que as alterações tenham efeito!')

          this.$emit('atualizarIndice', String(res.data.tipoIndice).toUpperCase())
        })
      },
      verificaImportacao() {
        this.modoAutenticacao = 'importacao'
        this.$refs['modal-auth-senha'].show()
      },
      async realizarImportacao() {
        try {
          this.isBusyImportacao = true
          let competencias = ''
          let dataAtual = new Date()
          for (let i = 1; i < 13; i++) {
            dataAtual.setMonth(dataAtual.getMonth() - 1)
            competencias = String(dataAtual.getFullYear()) + String(dataAtual.getMonth() + 1).padStart(2, '0') + competencias
            if (i != 12) competencias = '|' + competencias
          }

          let tipoIndice = this.indiceSelecionadoImportacao
          let url
          let param = {} // Declare a variável param aqui

          if (tipoIndice === 'IPCA') {
            url = `https://servicodados.ibge.gov.br/api/v3/agregados/7060/periodos/${competencias}/variaveis/63|2265?localidades=N1[all]&classificacao=315[7169]`
            let response = await useJwt.get(url)

            param = {
              taxas: response.data[0].resultados[0].series[0].serie,
              acumulados: response.data[1].resultados[0].series[0].serie,
              tipoIndice: tipoIndice,
            }
          } else if (tipoIndice === 'INPC') {
            url = `https://servicodados.ibge.gov.br/api/v3/agregados/7063/periodos/${competencias}/variaveis/44|2292?localidades=N1[all]&classificacao=315[7169]`
            let response = await useJwt.get(url)

            param = {
              taxas: response.data[0].resultados[0].series[0].serie,
              acumulados: response.data[1].resultados[0].series[0].serie,
              tipoIndice: tipoIndice,
            }
          }

          useJwt
            .post('cadastro/instituto/SetImportarTaxas', param)
            .then((response) => {
              if (response) this.$message.success('Importação realizada com sucesso!')
              this.isBusyImportacao = false
              this.$refs['modal-opcoes-importacao'].hide()
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Ocorreu um erro ao realizar a importação!')
              this.isBusyImportacao = false
              this.$refs['modal-opcoes-importacao'].hide()
            })
        } catch (error) {
          this.$message.error('Ocorreu um erro ao realizar a importação!')
          console.error(error)
          this.isBusyImportacao = false
          this.$refs['modal-opcoes-importacao'].hide()
        }
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.modoAutenticacao == 'salvarIndice') this.salvarIndice()
          if (this.modoAutenticacao == 'importacao') this.apresentaOpcoesIndice()
        }
      },
      validaFiltros(mesIni, anoIni, mesFim, anoFim) {
        let validado = true

        if (mesIni) if (mesIni > 12 || mesIni < 0) validado = false

        if (mesFim) if (mesFim > 12 || mesFim < 0) validado = false

        if (anoIni) if (anoIni > 2100 || anoIni < 1900) validado = false

        if (anoFim) if (anoFim > 2100 || anoFim < 1900) validado = false

        if (validado) {
          if (mesIni && anoIni && mesFim && anoFim) {
            let dataInicialFiltro = new Date(String(anoIni) + '/' + String(mesIni) + '/' + '01')
            let dataFinalFiltro = new Date(String(anoFim) + '/' + String(mesFim) + '/' + '01')
            if (dataInicialFiltro > dataFinalFiltro) validado = false
          }
        }

        if (!mesIni && anoIni) validado = false
        if (mesIni && !anoIni) validado = false
        if (!mesFim && anoFim) validado = false
        if (mesFim && !anoFim) validado = false

        if (!mesIni && !anoIni && !mesFim && !anoFim) validado = true

        return validado
      },
      apresentaOpcoesIndice() {
        this.indiceSelecionadoImportacao = ''
        this.$refs['modal-opcoes-importacao'].show()
      },
      buscaDadosIndiceSelecionado() {
        let parametro = {
          tipoIndice: this.userData.Instituto.tipoIndiceSelecionado,
        }

        useJwt
          .post('cadastro/instituto/GetIndiceSelecionado', parametro)
          .then((response) => {
            if (response.data) {
              this.indiceSelecionado = response.data.indiceSelecionado
              this.indiceCompetencia = formatarCompetenciaDescricao(response.data.indiceCompetencia)
              this.indiceTaxaAcumulada = String(response.data.indiceTaxaAcumulada).replace('.', ',') + '%'
              this.indiceTaxaMensal = String(response.data.indiceTaxaMensal).replace('.', ',') + '%'
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style>
  .position-relative {
    position: relative;
  }

  .icon-bottom-right {
    position: absolute;
    bottom: 10px; /* Ajuste conforme necessário */
    right: 10px; /* Ajuste conforme necessário */
    opacity: 0.2;
  }
</style>
