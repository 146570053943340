<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-card-text class="mb-1">
          <small class="textoObservacao"> Para mais informações do parâmetro, passe o mouse por cima dele. </small>
        </b-card-text>
        <b-row>
          <!-- LIMITADOR DE IDADE -->
          <b-col sm="2">
            <b-form-group label="Limitador de Idade (em anos)" label-for="limitadorIdade">
              <validation-provider #default="{ errors }" name="Limitador por Idade" rules="required|zero">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limitadorIdade"
                    v-model.number="optionsLocal.limitadorIdade"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limitador de Idade (70 anos)"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Limite de Parcelas -->
          <b-col sm="2">
            <b-form-group label="Limite de Parcelas (quantidade)" label-for="limiteParcelas">
              <validation-provider #default="{ errors }" name="limiteParcelas" rules="required|zero">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limiteParcelas"
                    v-model.number="optionsLocal.limiteParcelas"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limite de Parcelas"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- CAPAG  -->
          <b-col md="2">
            <b-form-group label="CAPAG" label-for="capag">
              <validation-provider #default="{ errors }" name="CAPAG" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  name="capag"
                  v-model.number="optionsLocal.capag"
                  :options="capagOpcoes"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Limite de Alocacao -->
          <b-col sm="2">
            <b-form-group label="Limite de Alocação (em %)" label-for="limiteAlocacao">
              <validation-provider
                #default="{ errors }"
                name="Limite de Alocação (em %)"
                rules="required|valor_minimo_number:0"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limiteAlocacao"
                    v-model.number="optionsLocal.limiteAlocacao"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limite de Alocação (%)"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Mes Vencimento -->
          <b-col md="2">
            <b-form-group label="Mês de vencimento da 1º Parcela" label-for="mesVencimento">
              <validation-provider #default="{ errors }" name="Mês de vencimento da 1º Parcela" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  id="mesVencimento"
                  name="mesVencimento"
                  v-model="optionsLocal.mesVencimento"
                  :options="mesVencimentoOpcoes"
                >
                </b-form-select>
                <b-tooltip placement="bottom" target="mesVencimento" noninteractive
                  >Quantos meses até o vencimento da primeira parcela, contabilizado a partir do mês da simulação</b-tooltip
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Valor máximo porEmpréstimos / Limitador de Empréstimos -->
          <b-col md="2">
            <b-form-group label="Valor máximo por Empréstimos" label-for="limitadorEmprestimos">
              <validation-provider #default="{ errors }" name="Limitador de Empréstimos" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  id="limitadorEmprestimos"
                  name="limitadorEmprestimos"
                  v-model="optionsLocal.limitadorEmprestimos"
                  :options="limitadorEmprestimosOpcoes"
                >
                </b-form-select>
                <b-tooltip placement="bottom" target="limitadorEmprestimos" noninteractive
                  >Limita o valor máximo do empréstimo concedido com base na remuneração mensal do tomador</b-tooltip
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- assinaturadig -->
          <b-col v-if="false" md="2" align-self="center">
            <b-form-group label="Permitir Assinatura Digital/Eletrônica" label-for="assinaturaDigital">
              <span>Não </span>
              <b-form-checkbox
                checked="false"
                class="custom-control-primary"
                v-model="optionsLocal.assinaturaDigital"
                name="assinaturaDigital"
                switch
                inline
              />
              <span>Sim</span>
            </b-form-group>
          </b-col>

          <!-- Parametro de ambiente de API -->
          <b-col v-if="userData.role == 'master'" md="2">
            <b-form-group label="Ambiente de API" label-for="ambienteApi">
              <validation-provider #default="{ errors }" name="Ambiente de API" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  id="ambienteApi"
                  name="ambienteApi"
                  v-model="optionsLocal.ambienteApi"
                  :options="ambientesOpcoes"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Tipo de Vencimento de Parcelas -->
          <b-col md="2">
            <b-form-group label="Tipo de Vencimento de Parcelas" label-for="tipoVencimentoParcela">
              <validation-provider #default="{ errors }" name="Tipo de Vencimento de Parcela" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  id="tipoVencimentoParcela"
                  name="tipoVencimentoParcela"
                  v-model="optionsLocal.tipoVencimentoParcela"
                  :options="tipoVencimentoParcelaOpcoes"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Dia Fixo -->
          <b-col v-if="optionsLocal.tipoVencimentoParcela == 'diaFixo'" md="2">
            <validation-provider #default="{ errors }" name="Dia Fixo" rules="required|min_value:1|max_value:28">
              <b-form-group label="Dia Fixo" label-for="diaFixo">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :disabled="acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="diaFixo"
                    v-model.number="optionsLocal.diaFixoVencimentoParcela"
                    class="form-control-merge"
                    placeholder="Dia Fixo"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Valor Mínimo por Empréstimo -->
          <b-col md="2">
            <b-form-group label="Valor Mínimo por Empréstimo" label-for="valorMinimoEmprestimo">
              <validation-provider #default="{ errors }" name="Valor Mínimo por Empréstimo" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="valorMinimoEmprestimo"
                    placeholder="Valor Mínimo por Empréstimo"
                    v-model="minimoEmprestimo"
                    v-number="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Valor Mínimo por Parcela -->
          <b-col md="2">
            <b-form-group label="Valor Mínimo por Parcela" label-for="valorMinimoParcela">
              <validation-provider #default="{ errors }" name="Valor Mínimo por Parcela" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="valorMinimoParcela"
                    placeholder="Valor Mínimo por Parcela"
                    v-model="minimoParcela"
                    v-number="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Validade da Proposta em Dias" label-for="validadePropostaDias">
              <validation-provider #default="{ errors }" name="Validade da Proposta em Dias" rules="required|zero">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="validadePropostaDias"
                    v-model.number="optionsLocal.validadePropostaDias"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Validade da Proposta em Dias"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group
              label="Dias de carência"
              label-for="diasDeCarencia"
              v-b-tooltip.hover.bottom="
                'O segurado poderá tomar um empréstimo somente após o período de carência a partir da sua data de admissão.'
              "
            >
              <validation-provider #default="{ errors }" name="Dias de carência" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="diasDeCarencia"
                    v-model.number="optionsLocal.diasDeCarencia"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Dias de carência"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import { updateEmprestimosConsignados } from '@/utils/services/instituto'

  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../../error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { formatarParaNumber, formatarValor } from '@/libs/utils'

  export default {
    components: {
      BCardActions,
      AutenticaSenha,
      ValidationObserver,
      ValidationProvider,
      required,
      NaoAutorizadoModal,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: this.generalData,
        number: null,
        minimoEmprestimo: null,
        minimoParcela: null,
        anoOpcao: [],
        pesquisar: {
          mesOpcoes: 1,
          anoOpcao: '',
          perPage: 25,
          currentPage: 1,
        },
        ambientesOpcoes: [
          { value: 'baseTeste', text: 'Ambiente de Teste' },
          { value: 'baseProducao', text: 'Ambiente de Produção' },
        ],
        mesOpcoes: [
          { value: '1', text: 'Janeiro' },
          { value: '2', text: 'Fevereiro' },
          { value: '3', text: 'Março' },
          { value: '4', text: 'Abril' },
          { value: '5', text: 'Maio' },
          { value: '6', text: 'Junho' },
          { value: '7', text: 'Julho' },
          { value: '8', text: 'Agosto' },
          { value: '9', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
        ],
        capagOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'naopossui', text: 'Não possui' },
          { value: 'a', text: 'A' },
          { value: 'b', text: 'B' },
          { value: 'c', text: 'C' },
          { value: 'd', text: 'D' },
        ],
        tipoVencimentoParcelaOpcoes: [
          { value: 'diaContrato', text: 'Dia do Contrato' },
          { value: 'ultimoDia', text: 'Último Dia' },
          { value: 'diaFixo', text: 'Dia Fixo' },
        ],
        tabelaDeCalculoOpcoes: [
          { value: 'ambas', text: 'AMBAS' },
          { value: 'price', text: 'PRICE' },
          { value: 'sac', text: 'SAC' },
        ],
        mesVencimentoOpcoes: [
          // { value: 0, text: '0' }, //Solicitaram para retirar
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
        ],
        limitadorContratosOpcoes: [
          { value: 0, text: '0' },
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
          { value: 13, text: '13' },
          { value: 14, text: '14' },
          { value: 15, text: '15' },
        ],
        limitadorEmprestimosOpcoes: [
          { value: 0, text: '0' },
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
          { value: 13, text: '13' },
          { value: 14, text: '14' },
          { value: 15, text: '15' },
          { value: 16, text: '16' },
          { value: 17, text: '17' },
          { value: 18, text: '18' },
          { value: 19, text: '19' },
          { value: 20, text: '20' },
        ],
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        //Padrão modal
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [25, 50, 100],
        pesquisar: { perPage: 25, currentPage: 1 },
        striped: true,

        //Campos
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'role', label: 'Perfil de Usuário' },
        ],
        items: [],
        acesso: {
          ConfiguracoesSistemaRestricaoEditar: false,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoEditar
      }
      this.carregaAno()
      this.formatarValoresMinimos()
    },
    methods: {
      carregaAno() {
        useJwt
          .pesquisar('cadastro/instituto/GetAnoIndices', {
            ...this.pesquisar,
          })
          .then((response) => {
            this.anoOpcao = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      limpar(e) {
        this.minimoParcela = null
        this.minimoEmprestimo = null
        this.optionsLocal = {
          ...this.optionsLocal,
          limitadorIdade: null,
          limiteParcelas: null,
          capag: null,
          limiteAlocacao: null,
          mesVencimento: null,
          limitadorEmprestimos: null,
          limitadorEmprestimos: null,
          ambienteApi: null,
          tipoVencimentoParcela: null,
          diaFixoVencimentoParcela: null,
          validadePropostaDias: null,
          diasDeCarencia: null,
        }
        this.$refs.formRestricoes.reset()
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (!this.optionsLocal.id) {
          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
          return
        }

        if (this.optionsLocal.tipoVencimentoParcela != 'diaFixo') this.optionsLocal.diaFixoVencimentoParcela = null

        const parametro = {
          ...this.optionsLocal,
          dataLimiteRecorte: this.optionsLocal.dataLimiteRecorte,
          mesVencimento: this.optionsLocal.mesVencimento,
          ambienteApi: this.optionsLocal.ambienteApi,
          valorMinimoEmprestimo: formatarParaNumber(this.minimoEmprestimo),
          valorMinimoParcela: formatarParaNumber(this.minimoParcela),
          habilitaReconhecimentoFacialEDocumentoComFoto: this.optionsLocal.habilitaReconhecimentoFacialEDocumentoComFoto,
          diasDeCarencia: this.optionsLocal.diasDeCarencia,
        }
        this.optionsLocal.valorMinimoEmprestimo = parametro.valorMinimoEmprestimo
        this.optionsLocal.valorMinimoParcela = parametro.valorMinimoParcela

        updateEmprestimosConsignados(this.optionsLocal.id, parametro)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              this.$router.go()
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao salvar as restrições')
          })
      },
      formatarValoresMinimos() {
        let minimoEmprestimo = this.optionsLocal.valorMinimoEmprestimo
        let minimoParcela = this.optionsLocal.valorMinimoParcela
        minimoEmprestimo = minimoEmprestimo ? formatarValor(minimoEmprestimo) : formatarValor(0)
        minimoParcela = minimoParcela ? formatarValor(minimoParcela) : formatarValor(0)
        this.minimoEmprestimo = minimoEmprestimo
        this.minimoParcela = minimoParcela
      },
    },
  }
</script>

<style lang="scss">
  #tipoVencimentoParcelas {
    margin-top: 6px;
  }
</style>
