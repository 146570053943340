<template>
  <div>
    <b-card>
      <validation-observer ref="formPoliticaSenhas">
        <b-form>
          <b-row md="12" class="mb-2">
            <b-col md="12">
              <h2>Política de senhas</h2>
            </b-col>

            <!-- Input de senha -->
            <b-col md="3">
              <b-form-group label="Quantidade de caracteres" label-for="senhaQuantidadeCaracteres">
                <validation-provider #default="{ errors }" name="Quantidade de caracteres" rules="required">
                  <b-form-select
                    id="senhaQuantidadeCaracteres"
                    name="senhaQuantidadeCaracteres"
                    v-model="optionsLocal.senhaQuantidadeCaractere"
                    :options="quantidadeCaracteresOpcoes"
                    placeholder="Quantidade caracteres"
                    :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
                  >
                  </b-form-select>
                  <b-tooltip placement="bottom" target="senhaQuantidadeCaracteres" noninteractive
                    >Quantidade mínima de caracteres que será utilizada na senha</b-tooltip
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Input senha minuscula -->
            <b-col md="auto">
              <b-form-group
                label="Deverá conter número?"
                label-for="senhaNumero"
                v-b-tooltip.hover.bottom="'Caso habilitado, a senha deverá conter um número'"
              >
                <validation-provider #default="{ errors }" name="Habilita Biometria Facial">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.senhaNumero"
                    name="senhaNumero"
                    id="senhaNumero"
                    switch
                    inline
                  >
                    {{ optionsLocal.senhaNumero ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Input caractere especial -->
            <b-col md="auto">
              <b-form-group
                label="Caractere especial?"
                label-for="senhaCaractereEspecial"
                v-b-tooltip.hover.bottom="'Caso habilitado, a senha deverá conter um caracter especial'"
              >
                <validation-provider #default="{ errors }">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.senhaCaractereEspecial"
                    name="senhaCaractereEspecial"
                    id="senhaCaractereEspecial"
                    switch
                    inline
                  >
                    {{ optionsLocal.senhaCaractereEspecial ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Input senha maiúscula -->
            <b-col md="auto">
              <b-form-group
                label="Letra maiúscula?"
                label-for="senhaLetraMaiuscula"
                v-b-tooltip.hover.bottom="'Caso habilitado, a senha deverá conter uma letra maiúscula'"
              >
                <validation-provider #default="{ errors }">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.senhaLetraMaiuscula"
                    name="senhaLetraMaiuscula"
                    id="senhaLetraMaiuscula"
                    switch
                    inline
                  >
                    {{ optionsLocal.senhaLetraMaiuscula ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Input senha minuscula -->
            <b-col md="auto">
              <b-form-group
                label="Letra minúscula?"
                label-for="senhaLetraMinuscula"
                v-b-tooltip.hover.bottom="'Caso habilitado, a senha deverá conter uma letra minúscula'"
              >
                <validation-provider #default="{ errors }">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.senhaLetraMinuscula"
                    name="senhaLetraMinuscula"
                    id="senhaLetraMinuscula"
                    switch
                    inline
                  >
                    {{ optionsLocal.senhaLetraMinuscula ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row md="12" style="margin-left: 0.1rem; margin-top: 5rem">
            <b-button
              id="savebutton"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="width: 160px"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="authESalve"
              :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
            >
              {{ !isBusy ? 'Salvar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
            <b-button
              id="clearbutton"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="width: 160px"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="limpar"
              :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
            >
              Limpar
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../error/nao-autorizado-modal.vue'
  import Ripple from 'vue-ripple-directive'
  import { VueEditor } from 'vue2-editor'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      NaoAutorizadoModal,
      VueEditor,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: this.generalData,
        isBusy: false,
        quantidadeCaracteresOpcoes: [
          { value: 0, text: '0' },
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
          { value: 13, text: '13' },
          { value: 14, text: '14' },
          { value: 15, text: '15' },
          { value: 16, text: '16' },
          { value: 17, text: '17' },
          { value: 18, text: '18' },
          { value: 19, text: '19' },
          { value: 20, text: '20' },
        ],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          ConfiguracoesSistemaPoliticaSenhasEditar: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaPoliticaSenhasEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaPoliticaSenhasEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaAplicativoEditar
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          ...this.optionsLocal,
          senhaQuantidadeCaractere: null,
          senhaNumero: false,
          senhaCaractereEspecial: false,
          senhaLetraMaiuscula: false,
          senhaLetraMinuscula: false,
        }
      },
      salvar() {
        this.$refs['formPoliticaSenhas'].validate().then((success) => {
          if (success) {
            const insertData = {
              ...this.optionsLocal,
            }
            useJwt
              .put(`cadastro/instituto/politicaSenhas/${this.optionsLocal.id}`, insertData)
              .then((res) => {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Registro alterado com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$message.error('Erro:' + error.response.data.msg)
                return
              })
          }
        })
      },
      authESalve() {
        this.$refs['formPoliticaSenhas'].validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
    },
    watch: {},
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }
</style>
