








































import { formatarValorPorcentagem } from '@/libs/utils'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const perPage = ref<number>(5)
    const currentPage = ref<number>(1)
    const fields = ref<any[]>([
      { key: 'ano', label: 'Ano' },
      { key: 'mes', label: 'Mês', formatter: (value: string) => (value ? String(value)?.padStart(2, '0') : '') },
      {
        key: 'taxaEmprestimoMensal',
        label: 'Taxa Empréstimo Mensal',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaDeOportunidade',
        label: 'Performance',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'jurosAtuarial',
        label: 'Juros Atuarial',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaAdministrativa',
        label: 'Taxa Administrativa',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaOscilacaoRiscos',
        label: 'Taxa Oscilação Riscos',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaFundoGarantia',
        label: 'Taxa Fundo Garantia',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaIndiceInflacionario',
        label: 'Taxa Índice Inflacionário',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
      {
        key: 'taxaEmprestimoAnual',
        label: 'Taxa Empréstimo Anual',
        formatter: (value: number) => (value ? formatarValorPorcentagem(value) : ''),
      },
    ])
    const sortBy = ref('')
    const sortDesc = ref(false)
    const sortDirection = ref('asc')

    return {
      perPage,
      currentPage,
      fields,
      sortBy,
      sortDesc,
      sortDirection,
    }
  },
})
