<template>
  <div>
    <b-card>
      <validation-observer ref="form">
        <b-form>
          <h2>Configurações do Totem</h2>
          <p>Para mais informações do parâmetro, passe o mouse em cima dele</p>
          <b-row>
            <!-- Cor Principal -->
            <b-col md="2">
              <b-form-group label="Cor Principal" label-for="cor" v-b-tooltip.hover.top="'Cor príncipal que ficará no totem'">
                <validation-provider #default="{ errors }" name="cor" rules="required">
                  <b-form-input id="cor" type="color" v-model="optionsLocal.Totem.corTotem" class="form-control" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Valor Máximo de Empréstimo -->
            <b-col md="2" v-b-tooltip.hover.top="'Valor máximo de empréstimo que poderá ser solicitado'">
              <b-form-group label="Valor Máximo de Empréstimo" label-for="valorMaximoEmprestimo">
                <validation-provider #default="{ errors }" name="Valor Máximo de Empréstimo" rules="required" totio>
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="valorMaximoEmprestimo"
                      type="text"
                      v-number="number"
                      v-model="optionsLocal.Totem.valorMaximoEmprestimoTotem"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Valor Máximo de Empréstimo"
                      placeholder="Valor Máximo de Empréstimo"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Taxa de Empréstimos [Ao Mês] -->
            <b-col
              md="2"
              v-b-tooltip.hover.top="'A taxa de empréstimo pode ser alterada em Parâmetro do sistema > Composição de Taxa'"
            >
              <b-form-group label="Taxa de Empréstimos [Ao Mês]" label-for="taxaEmprestimoMensal">
                <validation-provider #default="{ errors }" name="Taxa de Empréstimos [Ao Mês]" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="taxaEmprestimoMensalCalculadoraEterna"
                      :disabled="true"
                      type="text"
                      v-model="optionsLocal.taxaEmprestimoMensal"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Taxa de Empréstimos [Ao Mês]"
                      placeholder="Taxa de Empréstimos [Ao Mês]"
                    />
                    <b-input-group-append>
                      <span class="input-group-text">%</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Dia do Repasse -->
            <b-col md="2">
              <b-form-group label="Dia do Repasse" label-for="diaRepasse">
                <validation-provider #default="{ errors }" name="Dia do Repasse" rules="required|min_value:1|max_value:31">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      type="number"
                      id="diaRepasse"
                      v-model="optionsLocal.Totem.diaRepasseTotem"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Dia do Repasse"
                      placeholder="Dia do Repasse"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Dia do Fechamento da Folha -->
            <b-col md="2">
              <b-form-group label="Dia do Fechamento da Folha" label-for="diaVencimento">
                <validation-provider
                  #default="{ errors }"
                  name="Dia do Fechamento da Folha"
                  rules="required|min_value:1|max_value:31"
                >
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      type="number"
                      id="diaVencimento"
                      v-model="optionsLocal.Totem.diaVencimentoTotem"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Dia do Fechamento da Folha"
                      placeholder="Dia do Fechamento da Folha"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row md="12" style="margin-left: 0.1rem; margin-top: 5rem">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="width: 160px"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="authESalve"
            >
              {{ !isBusy ? 'Salvar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="width: 160px"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="limpar"
            >
              Limpar
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      Cleave,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: {},
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        valorHex: '',
        number: 0.0,
        cleveHexColor: {
          prefix: '#',
          delimiters: [],
          blocks: [7],
          uppercase: true,
        },
      }
    },
    mounted() {
      this.optionsLocal = {
        ...this.generalData,
        Totem: {
          corTotem: this.generalData?.Totem?.corTotem || '#0000CC',
          valorMaximoEmprestimoTotem: this.generalData?.Totem?.valorMaximoEmprestimoTotem || 0,
          diaRepasseTotem: this.generalData?.Totem?.diaRepasseTotem || 0,
          diaVencimentoTotem: this.generalData?.Totem?.diaVencimentoTotem || 0,
        },
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          ...this.optionsLocal,
        }
      },
      salvar() {
        if (!this.optionsLocal.id) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          let parametros = {
            corTotem: this.optionsLocal.Totem.corTotem,
            valorMaximoEmprestimoTotem: this.optionsLocal.Totem.valorMaximoEmprestimoTotem,
            diaRepasseTotem: this.optionsLocal.Totem.diaRepasseTotem,
            diaVencimentoTotem: this.optionsLocal.Totem.diaVencimentoTotem,
          }

          useJwt
            .put(`/instituto/restricoes/totem/${this.optionsLocal.id}`, parametros)
            .then(() => {
              this.$message.success('Parametrizações salvas com sucesso')
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Houve um erro ao salvar parametrizações')
            })
        }
      },
      authESalve() {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      acessarTotem() {
        this.$router.push({ path: `/totemConsig`, query: { sigla } })
      },
    },
    watch: {},
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }
</style>
