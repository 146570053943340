







import { defineComponent } from '@vue/composition-api'
import PosFixado from '../../../../components/configuracoes/sistema/pos-fixado/PosFixado.vue'
import HistoricoPosFixado from '../../../../components/configuracoes/sistema/pos-fixado/HistoricoPosFixado.vue'

export default defineComponent({
  props: {
    generalData: {
      type: Object || null,
    },
  },
  setup(props) {
    return {}
  },
  components: { PosFixado, HistoricoPosFixado },
})
