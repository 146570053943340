<template>
  <div>
    <b-tabs pills>
      <b-tab title="IPCA e INPC" active>
        <b-card-text>
          <ConfiguracoesIPCAEINPC :generalData="optionsLocal" />
        </b-card-text>
      </b-tab>
      <b-tab title="IGP-M">
        <b-card-text>
          <ConfiguracoesIGPM :generalData="optionsLocal" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ConfiguracoesIGPM from './ConfiguracoesIGPM.vue'
  import ConfiguracoesIPCAEINPC from './ConfiguracoesIPCAEINPC.vue'

  export default {
    components: {
      ConfiguracoesIPCAEINPC,
      ConfiguracoesIGPM,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    methods: {},
  }
</script>
