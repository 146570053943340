<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-card-text class="mb-1">
          <small class="textoObservacao"> Para mais informações do parâmetro, passe o mouse por cima dele. </small>
        </b-card-text>
        <b-row>
          <!-- Limite de Parcelas -->
          <b-col md="3">
            <b-form-group label="Limite de Parcelas (quantidade)" label-for="limiteParcelas">
              <validation-provider #default="{ errors }" name="Limite de parcelas" rules="required|max_value:120|zero">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limiteParcelas"
                    v-model.number="limiteParcelas"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limite de Parcelas"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Valor Máximo Empréstimo -->
          <b-col md="2">
            <b-form-group label="Valor Máximo de Empréstimo" label-for="valorMaximoEmprestimo">
              <validation-provider #default="{ errors }" name="Valor Máximo de Empréstimo" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    type="text"
                    v-number="number"
                    id="valorMaximoEmprestimo"
                    v-model="valorMaximoEmprestimo"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Valor Máximo de Empréstimo"
                    placeholder="Valor Máximo de Empréstimo"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Taxa de Juros Mensal -->
          <b-col md="2">
            <b-form-group label="Taxa de Empréstimos [Ao Mês]" label-for="taxaEmprestimoMensal">
              <validation-provider #default="{ errors }" name="Taxa de Empréstimos [Ao Mês]" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="true"
                    type="text"
                    v-number="number"
                    id="taxaEmprestimoMensalCalculadoraEterna"
                    v-model="optionsLocal.taxaEmprestimoMensal"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Taxa de Empréstimos [Ao Mês]"
                    placeholder="Taxa de Empréstimos [Ao Mês]"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Dia do Repasse" label-for="diaRepasse">
              <validation-provider #default="{ errors }" name="Dia do Repasse" rules="required|min_value:1|max_value:31">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    type="number"
                    id="diaRepasse"
                    v-model="diaRepasse"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Dia do Repasse"
                    placeholder="Dia do Repasse"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Dia do Fechamento da Folha" label-for="diaVencimento">
              <validation-provider
                #default="{ errors }"
                name="Dia do Fechamento da Folha"
                rules="required|min_value:1|max_value:31"
              >
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    type="number"
                    id="diaVencimento"
                    v-model="diaVencimento"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Dia do Fechamento da Folha"
                    placeholder="Dia do Fechamento da Folha"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

  import { updateCalculadoraExterna } from '@/utils/services/instituto'
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../../error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCardActions,
      AutenticaSenha,
      ValidationObserver,
      ValidationProvider,
      required,
      NaoAutorizadoModal,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: this.generalData,
        limiteParcelas: 72, //Default
        number: 0.0,
        acesso: {},
        items: [],
        valorMaximoEmprestimo: '',
        diaRepasse: '',
        diaVencimento: '',
        acesso: {
          ConfiguracoesSistemaRestricaoEditar: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoEditar
      }
      this.limiteParcelas = this.optionsLocal?.calculadoraExternaParametro?.limiteParcelas
      this.valorMaximoEmprestimo = Number(this.optionsLocal?.calculadoraExternaParametro?.valorMaximoEmprestimo).toFixed(2)
      this.diaRepasse = this.optionsLocal?.calculadoraExternaParametro?.diaDoRepasse
      this.diaVencimento = this.optionsLocal?.calculadoraExternaParametro?.diaFechamentoFolha
    },
    methods: {
      limpar() {
        this.limiteParcelas = null
        this.valorMaximoEmprestimo = null
        this.diaRepasse = null
        this.diaVencimento = null
        this.optionsLocal = {
          ...this.optionsLocal,
        }
        this.$refs.formRestricoes.reset()
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (!this.optionsLocal.id) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          let parametros = {
            limiteParcelas: this.limiteParcelas,
            valorMaximoEmprestimo: this.valorMaximoEmprestimo,
            diaDoRepasse: this.diaRepasse,
            diaFechamentoFolha: Number(this.diaVencimento),
          }

          updateCalculadoraExterna(this.optionsLocal.id, parametros)
            .then(() => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch((error) => {
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erro: ' + error.response.data.msg,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        }
      },
    },
  }
</script>

<style lang="scss"></style>
