<template>
  <div>
    <!-- Form -->
    <b-row>
      <b-col cols="12">
        <b-card title="Cadastro de Critérios de Elegibilidade">
          <validation-observer ref="formIndRef">
            <b-form class="form-row">
              <!-- Idade Mínima  -->
              <b-col cols="2">
                <b-form-group label="Idade Mínima" label-for="idadeMinima">
                  <validation-provider #default="{ errors }" name="Idade Mínima" :rules="{ required: true }">
                    <b-form-input
                      :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                      id="idadeMinima"
                      name="idadeMinima"
                      type="number"
                      v-model="dados.idadeMinima"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Idade Máxima  -->
              <b-col cols="2">
                <b-form-group label="Idade Máxima" label-for="idadeMaxima">
                  <validation-provider #default="{ errors }" name="Idade Máxima" :rules="{ required: true }">
                    <b-form-input
                      :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                      id="idadeMaxima"
                      name="idadeMaxima"
                      type="number"
                      v-model="dados.idadeMaxima"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Número Máximo de Parcelas  -->
              <b-col cols="2">
                <b-form-group label="Número Máximo de Parcelas" label-for="numeroMaximoParcelas">
                  <validation-provider #default="{ errors }" name="Número Máximo de Parcelas" rules="required">
                    <b-form-input
                      :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                      name="numeroMaximoParcelas"
                      v-model="dados.numeroMaximoParcelas"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="2" v-if="tipo == 'Aposentado'">
                <b-form-group
                  label="Portador de Moléstia Grave?"
                  label-for="portadorMolestiaGrave"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="portadorMolestiaGrave"
                    v-model="portadorMolestiaGrave"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="portadorMolestiaGrave"
                    buttons
                    :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col cols="3" v-if="tipo == 'Aposentado'">
                <b-form-group
                  label="Portador de Doença Incapacitante"
                  label-for="portadorDoencaIncapacitante"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="portadorDoencaIncapacitante"
                    v-model="portadorDoencaIncapacitante"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="portadorDoencaIncapacitante"
                    buttons
                    :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

              <!-- Actions -->
              <b-col cols="auto">
                <b-button
                  :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  style="width: 160px"
                  variant="outline-secondary"
                  class="mt-2 mr-1 mt-18em"
                  @click.prevent="limpar"
                >
                  Limpar
                </b-button>
                <b-button
                  :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  style="width: 160px"
                  variant="primary"
                  class="mt-2 mt-18em"
                  @click.prevent="authESalve"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>

    <!-- Tabela -->
    <b-row>
      <b-col cols="12">
        <b-card v-show="items.length > 0">
          <b-col cols="12">
            <!-- Tabela -->
            <b-table
              striped
              responsive
              class="position-relative"
              :per-page="pesquisar.perPage"
              :items="filteredItems"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :current-page="pesquisar.currentPage"
            >
              <!-- Campo Opções -->
              <template #cell(opcoes)="row">
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      title="Este botão contém as opções disponíveis para este registro"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </template>
                  <b-dropdown-item
                    @click="editar(row.item)"
                    :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  >
                    <feather-icon icon="EditIcon" size="16" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="authEExclui(row.item.id)"
                    :disabled="!acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                  >
                    <feather-icon icon="XIcon" size="16" />
                    <span class="align-middle ml-50">Excluir</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template #cell(categorias)="row">
                <span v-for="item in row.item.categorias" :key="item">
                  <b-badge pill variant="primary">
                    {{ item }}
                  </b-badge>
                </span>
              </template>
              <template #cell(observacoes)="row">
                <b-badge pill variant="primary" v-if="row.item.portadorMolestiaGrave"> Moléstia Grave </b-badge>
                <b-badge pill variant="primary" v-if="row.item.portadorDoencaIncapacitante"> Doença Incapacitante </b-badge>
              </template>
            </b-table>

            <!-- Paginação -->
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                style="align-items: center"
                label="Por Página"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
              </b-form-group>

              <!-- pagination -->
              <b-pagination
                v-model="pesquisar.currentPage"
                :total-rows="totalRows"
                :per-page="pesquisar.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>

            <!-- Modal de Senha -->
            <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
              <AutenticaSenha @autenticarEvent="autenticarMethod" />
            </b-modal>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import Cleave from 'vue-cleave-component'
  import { formatarAposentadoInativo, formatarParaNumber } from '@/libs/utils'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      Cleave,
      AutenticaSenha,
    },
    directives: {
      Ripple,
    },
    computed: {
      filteredItems() {
        let filtered = this.items.filter((item) => item.categorias.includes(formatarAposentadoInativo(this.categoria)))
        filtered.forEach((item) => {
          item = this.item
        })
        return filtered
      },
    },
    props: {
      tipo: {
        type: String,
        default: '',
      },
      userData: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        categoria: this.tipo,
        userDate: this.userData,
        pesquisar: { perPage: 25, currentPage: 1 },
        acesso: {},
        items: [],
        saveMode: 'Create',
        mode: '',
        number: {},
        portadorDoencaIncapacitante: false,
        portadorMolestiaGrave: false,
        dados: {
          idadeMaxima: null,
          idadeMinima: null,
          numeroMaximoParcelas: null,
          categorias: [],
        },

        optionsSimNao: [
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ],

        //Campos tabela
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'idadeMinima', label: 'Idade Mínima' },
          { key: 'idadeMaxima', label: 'Idade Máxima' },
          { key: 'numeroMaximoParcelas', label: 'Número Máximo de Parcelas' },
          { key: 'categorias', label: 'Categorias' },
          { key: 'observacoes', label: 'Observações' },
        ],

        acesso: {
          ConfiguracoesSistemaCriteriosElegibilidadeEditar: false,
        },

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
      }
    },
    mounted() {
      // TODO: Criar workflow da tela
      this.carregarGrid()
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar =
          this.userData.GrupoAcesso.ConfiguracoesSistemaCriteriosElegibilidadeEditar
      }
    },
    methods: {
      limpar() {
        this.saveMode = 'Create'
        this.dados = {}
        this.portadorDoencaIncapacitante = false
        this.portadorMolestiaGrave = false
        this.$refs.formIndRef.reset()
      },
      authESalve() {
        this.$refs.formIndRef.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.mode == 'authESalve') {
            this.salvar()
          }

          if (this.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      salvar() {
        if (this.dados.idadeMinima > this.dados.idadeMaxima) {
          this.$message.error('Idade Mínima não pode ser maior que Idade Máxima')
          return
        }

        let dadosInsert = {
          idadeMaxima: formatarParaNumber(this.dados.idadeMaxima),
          idadeMinima: formatarParaNumber(this.dados.idadeMinima),
          numeroMaximoParcelas: formatarParaNumber(this.dados.numeroMaximoParcelas),
          categorias: formatarAposentadoInativo(this.categoria),
          institutoId: this.userData.institutoSelecionado,
          portadorDoencaIncapacitante: this.portadorDoencaIncapacitante,
          portadorMolestiaGrave: this.portadorMolestiaGrave,
        }

        let dadosUpdate = {
          idadeMaxima: formatarParaNumber(this.dados.idadeMaxima),
          idadeMinima: formatarParaNumber(this.dados.idadeMinima),
          portadorDoencaIncapacitante: this.portadorDoencaIncapacitante,
          portadorMolestiaGrave: this.portadorMolestiaGrave,
          numeroMaximoParcelas: formatarParaNumber(this.dados.numeroMaximoParcelas),
          categorias: formatarAposentadoInativo(this.categoria),
          institutoId: this.userData.institutoSelecionado,
          id: formatarParaNumber(this.dados.id),
        }

        if (this.saveMode == 'Create') this.createCriterio(dadosInsert)
        if (this.saveMode == 'Update') this.updateCriterio(dadosUpdate)
        this.carregarGrid()
      },
      createCriterio(dadosInsert) {
        useJwt
          .post(`cadastro/instituto/registrarCriteriosElegibilidade`, dadosInsert)
          .then((response) => {
            if (response.data?.status == 'erro') {
              this.$message.error(response.data.mensagem)
              return
            } else {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: { confirmButton: 'btn btn-primary' },
                buttonsStyling: false,
              }).then((response) => {
                this.limpar()
                this.carregarGrid()
              })
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      updateCriterio(dadosUpdate) {
        useJwt
          .post(`cadastro/instituto/updateCriteriosElegibilidade`, dadosUpdate)
          .then((response) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: { confirmButton: 'btn btn-primary' },
              buttonsStyling: false,
            }).then((response) => {
              this.limpar()
              this.carregarGrid()
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarGrid() {
        let institutoId = this.userData.institutoSelecionado

        useJwt
          .pesquisar('cadastro/instituto/GetPesquisarCriteriosElegibilidade', {
            institutoId: institutoId,
          })
          .then((response) => {
            this.totalRows = response.data.length
            let criterios = response.data

            if (criterios?.length > 0) {
              criterios.forEach((criterio) => {
                let arrayAux = criterio.CriteriosElegibilidadeCategoria
                let categorias = []

                arrayAux.forEach((item) => {
                  categorias.push(item.categoria)
                })
                criterio['categorias'] = categorias
              })
            }

            this.items = criterios
          })
          .catch((error) => {
            console.error(error)
          })
      },
      authEExclui(item) {
        this.items.item = item
        this.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      editar(item) {
        this.limpar()
        this.saveMode = 'Update'
        this.dados.id = item.id
        this.dados.idadeMaxima = formatarParaNumber(item.idadeMaxima)
        this.dados.idadeMinima = formatarParaNumber(item.idadeMinima)
        this.dados.numeroMaximoParcelas = formatarParaNumber(item.numeroMaximoParcelas)
        this.dados.categorias = [this.categoria]
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/instituto/excluirCriteriosElegibilidade`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
    },
    watch: {
      portadorMolestiaGrave: function (val) {
        if (val) {
          this.portadorDoencaIncapacitante = false
        }
      },
      portadorDoencaIncapacitante: function (val) {
        if (val) {
          this.portadorMolestiaGrave = false
        }
      },
    },
  }
</script>

<style>
  #grupoCategoriasConfiguracaoCriteriosElegibilidade > div {
    display: flex;
  }

  .mt-18em {
    margin-top: 1.8em !important;
  }
</style>
