var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('h2',[_vm._v("Configurações do Totem")]),_c('p',[_vm._v("Para mais informações do parâmetro, passe o mouse em cima dele")]),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Cor príncipal que ficará no totem'),expression:"'Cor príncipal que ficará no totem'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Cor Principal","label-for":"cor"}},[_c('validation-provider',{attrs:{"name":"cor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"cor","type":"color"},model:{value:(_vm.optionsLocal.Totem.corTotem),callback:function ($$v) {_vm.$set(_vm.optionsLocal.Totem, "corTotem", $$v)},expression:"optionsLocal.Totem.corTotem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Valor máximo de empréstimo que poderá ser solicitado'),expression:"'Valor máximo de empréstimo que poderá ser solicitado'",modifiers:{"hover":true,"top":true}}],attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor Máximo de Empréstimo","label-for":"valorMaximoEmprestimo"}},[_c('validation-provider',{attrs:{"name":"Valor Máximo de Empréstimo","rules":"required","totio":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_vm._v("R$")])]),_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"form-control-merge",attrs:{"id":"valorMaximoEmprestimo","type":"text","state":errors.length > 0 ? false : null,"name":"Valor Máximo de Empréstimo","placeholder":"Valor Máximo de Empréstimo"},model:{value:(_vm.optionsLocal.Totem.valorMaximoEmprestimoTotem),callback:function ($$v) {_vm.$set(_vm.optionsLocal.Totem, "valorMaximoEmprestimoTotem", $$v)},expression:"optionsLocal.Totem.valorMaximoEmprestimoTotem"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('A taxa de empréstimo pode ser alterada em Parâmetro do sistema > Composição de Taxa'),expression:"'A taxa de empréstimo pode ser alterada em Parâmetro do sistema > Composição de Taxa'",modifiers:{"hover":true,"top":true}}],attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Taxa de Empréstimos [Ao Mês]","label-for":"taxaEmprestimoMensal"}},[_c('validation-provider',{attrs:{"name":"Taxa de Empréstimos [Ao Mês]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"taxaEmprestimoMensalCalculadoraEterna","disabled":true,"type":"text","state":errors.length > 0 ? false : null,"name":"Taxa de Empréstimos [Ao Mês]","placeholder":"Taxa de Empréstimos [Ao Mês]"},model:{value:(_vm.optionsLocal.taxaEmprestimoMensal),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "taxaEmprestimoMensal", $$v)},expression:"optionsLocal.taxaEmprestimoMensal"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Dia do Repasse","label-for":"diaRepasse"}},[_c('validation-provider',{attrs:{"name":"Dia do Repasse","rules":"required|min_value:1|max_value:31"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"diaRepasse","state":errors.length > 0 ? false : null,"name":"Dia do Repasse","placeholder":"Dia do Repasse"},model:{value:(_vm.optionsLocal.Totem.diaRepasseTotem),callback:function ($$v) {_vm.$set(_vm.optionsLocal.Totem, "diaRepasseTotem", $$v)},expression:"optionsLocal.Totem.diaRepasseTotem"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Dia do Fechamento da Folha","label-for":"diaVencimento"}},[_c('validation-provider',{attrs:{"name":"Dia do Fechamento da Folha","rules":"required|min_value:1|max_value:31"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"diaVencimento","state":errors.length > 0 ? false : null,"name":"Dia do Fechamento da Folha","placeholder":"Dia do Fechamento da Folha"},model:{value:(_vm.optionsLocal.Totem.diaVencimentoTotem),callback:function ($$v) {_vm.$set(_vm.optionsLocal.Totem, "diaVencimentoTotem", $$v)},expression:"optionsLocal.Totem.diaVencimentoTotem"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticStyle:{"margin-left":"0.1rem","margin-top":"5rem"},attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" "+_vm._s(!_vm.isBusy ? 'Salvar' : '')+" "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }