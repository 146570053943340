<template>
  <div>
    <b-card>
      <b-form>
        <b-row>
          <b-col>
            <h2 class="mb-2">Histórico de Pós Fixado</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group label="Data Inicial" label-for="dataInicial">
              <validation-provider #default="{ errors }" name="Data Inicial">
                <b-input-group>
                  <cleave
                    id="dataInicial"
                    v-model="dataInicial"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    class="form-control cleave-height"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="dataInicialDatePicker"
                      size="sm"
                      button-variant="primary"
                      class="datePickerWithInput"
                      right
                      button-only
                      :hide-header="true"
                      :initial-date="new Date()"
                      :state="errors[0] ? false : null"
                      label-help=""
                      label-calendar="Calendário"
                      label-close-button="Fechar"
                      label-current-month="Mês atual"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mês"
                      label-next-year="Próximo ano"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mês anterior"
                      label-prev-year="Ano anterior"
                      label-select-month="Selecione o mês"
                      label-select-year="Selecione o ano"
                      label-selected="Selecionado"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-reset-button="Resetar"
                      label-today-button="Hoje"
                      label-today="Hoje"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                      aria-controls="dataInicial"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Data Final" label-for="dataFinal">
              <validation-provider #default="{ errors }" name="Data Inicial">
                <b-input-group>
                  <cleave
                    id="dataFinal"
                    v-model="dataFinal"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    class="form-control cleave-height"
                    :options="options.cleaveDate"
                    :raw="false"
                    :rules="{
                      dataPassada: {
                        value: dataInicial,
                        dataParametro: dataFinal,
                        stringParametro: 'data de inicial',
                      },
                    }"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="dataFinalDatePicker"
                      size="sm"
                      button-variant="primary"
                      class="datePickerWithInput"
                      right
                      button-only
                      :hide-header="true"
                      :initial-date="new Date()"
                      :state="errors[0] ? false : null"
                      label-help=""
                      label-calendar="Calendário"
                      label-close-button="Fechar"
                      label-current-month="Mês atual"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mês"
                      label-next-year="Próximo ano"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mês anterior"
                      label-prev-year="Ano anterior"
                      label-select-month="Selecione o mês"
                      label-select-year="Selecione o ano"
                      label-selected="Selecionado"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-reset-button="Resetar"
                      label-today-button="Hoje"
                      label-today="Hoje"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                      aria-controls="dataFinal"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
        </b-row>
        <b-row md="12" class="mt-1" v-if="!isBusy" v-show="this.itemsTabela.length > 0">
          <template v-if="isBusy">
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Carregando Tabela...</strong>
            </div>
          </template>
          <!-- TOTALIZADORES -->
          <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
            <b-table
              id="tabelaGrid"
              ref="tabelaGrid"
              style="overflow-x: auto; -webkit-overflow-scrolling: touch"
              outlined
              striped
              :per-page="perPage"
              :current-page="currentPage"
              :fields="this.currentFields"
              :items="this.itemsTabela"
              responsive="sm"
              class="position-relative custom-rounded-table"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            />
          </div>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarValorPorcentagem } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      Cleave,
      useJwt,
      formatarData,
      formatarValorPorcentagem,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dataInicial: '',
        dataInicialDatePicker: null,
        isBusy: false,
        dataFinal: '',
        dataFinalDatePicker: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        itemsTabela: [],
        totalRows: 1,
        sortBy: 'ano',
        sortDesc: true,
        sortDirection: 'desc',
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        currentFields: [
          { key: 'ano', label: 'Ano' },
          { key: 'mes', label: 'Mês' },
          { key: 'spread', label: 'Spread' },
          { key: 'taxaOportunidade', label: 'Taxa de Performance' },
          { key: 'jurosAtuariais', label: 'Juros Atuariais' },
          { key: 'taxaAdministracao', label: 'Taxa Administração' },
          { key: 'taxaOscilacaoRisco', label: 'Taxa Oscilação Risco' },
          { key: 'fundoGarantidor', label: 'Fundo Garantidor' },
          { key: 'indiceInflacionario', label: 'Índice Inflacionário' },
          { key: 'permiteDeflacao', label: 'Permite Deflação' },
        ],
      }
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
    methods: {
      carregarGrid() {
        this.isBusy = true

        const filtro = {
          institutoId: this.userData.institutoSelecionado,
          dataInicial: this.dataInicial,
          dataFinal: this.dataFinal,
          skip: 0,
          take: 100,
        }

        useJwt
          .post('cadastro/instituto/GetHistoricoTaxasPosFixado', filtro)
          .then((response) => {
            this.itemsTabela = response.data.map((item) => ({
              ...item,
              mes: item.mes,
              ano: item.ano,
              jurosAtuariais: formatarValorPorcentagem(item.taxajurosAtuariaisPosFixado),
              fundoGarantidor: formatarValorPorcentagem(item.taxafundoGarantidorPosFixado),
              taxaAdministracao: formatarValorPorcentagem(item.taxaAdministrativaPosFixado),
              taxaOportunidade: formatarValorPorcentagem(item.taxaOportunidadePosFixado),
              taxaOscilacaoRisco: formatarValorPorcentagem(item.taxaOscilacaoRiscosPosFixado),
              spread: formatarValorPorcentagem(item.taxaSpreadPosFixado),
              permiteDeflacao: item.permiteDeflacao ? 'Sim' : 'Não',
              indiceInflacionario: formatarValorPorcentagem(item.taxaIndiceInflacionarioPosFixado),
            }))
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>
