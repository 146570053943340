<template>
  <div>
    <b-tabs pills>
      <b-tab title="Empréstimos Consignados">
        <b-card-text>
          <restricoesEmprestimoConsignado :userData="userData" :generalData="optionsLocal" />
        </b-card-text>
      </b-tab>
      <b-tab title="Renegociação">
        <b-card-text>
          <restricoesRenegociacao :userData="userData" :generalData="renegociacaoParametro" />
        </b-card-text>
      </b-tab>
      <b-tab title="Calculadora Externa">
        <b-card-text>
          <RestricoesCalculadoraExterna :userData="userData" :generalData="optionsLocal" />
        </b-card-text>
      </b-tab>
      <b-tab title="Tipo de Cálculo">
        <b-card-text>
          <RestricoesTipoDeCalculo :userData="userData" :generalData="optionsLocal" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import RestricoesCalculadoraExterna from './RestricoesCalculadoraExterna.vue'
  import restricoesEmprestimoConsignado from './RestricoesEmprestimoConsignado.vue'
  import restricoesRenegociacao from './RestricoesRenegociacao.vue'
  import RestricoesTipoDeCalculo from './RestricoesTipoDeCalculo.vue'

  export default {
    components: {
      restricoesEmprestimoConsignado,
      restricoesRenegociacao,
      RestricoesCalculadoraExterna,
      RestricoesTipoDeCalculo,
    },
    mounted() {},
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: this.generalData,
        renegociacaoParametro: { ...this.generalData.renegociacaoParametro, id: this.generalData.id },
      }
    },
  }
</script>
