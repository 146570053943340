<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Pesquisa">
          <b-row>
            <!-- Competência inicial de pesquisa -->
            <b-col md="auto">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <cleave
                  id="dataInicial"
                  class="form-control"
                  type="text"
                  placeholder="MM/AAAA"
                  v-model="pesquisar.dataInicial"
                  :options="cleaveCompetencia"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefConsultar"
                />
              </b-form-group>
            </b-col>

            <!-- Competência final de pesquisa -->
            <b-col md="auto">
              <b-form-group label="Data Final" label-for="dataFinal">
                <cleave
                  id="dataFinal"
                  class="form-control"
                  type="text"
                  placeholder="MM/AAAA"
                  v-model="pesquisar.dataFinal"
                  :options="cleaveCompetencia"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                  :disabled="!acesso.ConfiguracoesSistemaIndiceRefConsultar"
                />
              </b-form-group>
            </b-col>

            <!-- Pesquisar -->
            <b-col md="auto">
              <b-button
                :disabled="isBusy || !acesso.ConfiguracoesSistemaIndiceRefConsultar"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="primary"
                class="mt-2"
                @click.prevent="goPesquisar()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>

            <!-- Novo Cadastro -->
            <b-col md="auto">
              <b-button
                :disabled="isBusy || !acesso.ConfiguracoesSistemaIndiceRefCriarNovo"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="primary"
                class="mt-2"
                @click.prevent="apresentaModalCadastro()"
              >
                {{ !isBusy ? 'Novo Cadastro' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-show="items.length > 0">
      <b-row>
        <b-col cols="12">
          <!-- Tabela -->
          <b-table
            v-show="items.length > 0"
            striped
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                    :disabled="!acesso.ConfiguracoesSistemaIndiceRefEditar"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>
                <b-dropdown-item @click="editarCadastro(row.item)">
                  <feather-icon icon="EditIcon" size="16" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(taxa)="row">{{ row.item.taxa }}%</template>
            <template #cell(taxaAcumulado)="row">{{ row.item.taxaAcumulado }}%</template>
          </b-table>

          <!-- Paginação -->
          <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              style="align-items: center"
              label="Por Página"
              label-cols="5"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" class="ml-2" />
            </b-form-group>

            <!-- pagination -->
            <b-pagination
              first-number
              last-number
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <!-- Modal de Cadastro do IGPM -->
    <b-modal
      id="modal-cadastro-igpm"
      ref="modal-cadastro-igpm"
      centered
      no-close-on-backdrop
      size="lg"
      :title="mode == 'edit' ? 'Editar IGPM' : 'Cadastrar Novo IGP-M'"
    >
      <ModalCadastroIGPM
        ref="formCadastroIGPM"
        :dataUpdate="dataUpdate"
        :generalData="optionsLocal"
        @fecharModal="fecharModalCadastro"
      />

      <template #modal-footer="{}">
        <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro-igpm')"> Fechar </b-button>
        <b-button variant="primary" @click="autenticarSalvar" :disabled="carregando">
          {{ !carregando ? 'Salvar' : '' }}
          <b-spinner small v-if="carregando" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import { isLetter, msgError, msgSuccess, formatarData } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import ModalCadastroIGPM from './components/modalCadastroIGPM.vue'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
      Cleave,
      msgError,
      msgSuccess,
      ModalCadastroIGPM,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dadosInstitutoSelecionado: {},
        isBusy: false,
        isBusyImportacao: false,
        carregando: false,
        mode: '',

        //Tabela e Paginação
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 10,
        currentPage: 1,
        fields: [
          { key: 'opcoes', label: 'OPÇÕES' },
          {
            key: 'ano',
            sortable: true,
          },
          {
            key: 'mes',
            label: 'Mês',
            sortable: true,
          },
          {
            key: 'tipoIndice',
            label: 'Tipo Índice',
            sortable: true,
          },
          {
            key: 'taxa',
            sortable: true,
          },
          {
            key: 'taxaAcumulado',
            sortable: true,
          },
          {
            key: 'updatedAt',
            label: 'Data de Atualização do Índice',
            formatter: (value) => {
              return value ? formatarData(value) : ''
            },
            sortable: true,
          },
        ],
        totalRows: 0,
        items: [],
        pesquisar: {
          tipoIndice: 'igpm',
          ano: new Date().getFullYear(),
          mes: new Date().getMonth() + 1,
        },
        pageOptions: [10, 25, 50, 100],

        dataUpdate: {},
        indiceSelecionadoImportacao: '',
        indicesOpcoesImportacao: [
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
        ],
        number: {},
        dados: {
          tipoIndice: '',
        },
        modoAutenticacao: '',
        isLetter,
        cleaveCompetencia: {
          blocks: [2, 4],
          delimiter: '/',
          numericOnly: true,
        },
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        indiceOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'ipca', text: 'IPCA' },
          { value: 'inpc', text: 'INPC' },
          { value: 'igpm', text: 'IGPM' },
        ],
        acesso: {
          ConfiguracoesSistemaIndiceRefConsultar: null,
          ConfiguracoesSistemaIndiceRefCriarNovo: null,
          ConfiguracoesSistemaIndiceRefEditar: null,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = true
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = true
        this.acesso.ConfiguracoesSistemaIndiceRefEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefConsultar
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefCriarNovo
        this.acesso.ConfiguracoesSistemaIndiceRefEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefEditar
      }

      this.dadosInstitutoSelecionado = this.userData.Instituto
      this.montaAnoOpcoes()

      // Seta o tipo do índice para "Selecionar" e limpa a validação de vazio
      this.dados.tipoIndice = ''

      // Seta o tipo do índice de acordo com o instituto selecionado
      this.dados.tipoIndice = this.dadosInstitutoSelecionado.tipoIndiceSelecionado?.toLowerCase()
      this.pesquisar.tipoIndice = this.dados.tipoIndice
    },
    methods: {
      goPesquisar() {
        this.isBusy = true
        let mesCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(0, 2))
        let anoCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(3, 7))
        let mesCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(0, 2))
        let anoCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(3, 7))

        let filtroValidado = this.validaFiltros(
          mesCompetenciaInicial,
          anoCompetenciaInicial,
          mesCompetenciaFinal,
          anoCompetenciaFinal,
        )

        let param = {
          mesCompetenciaInicial: mesCompetenciaInicial,
          anoCompetenciaInicial: anoCompetenciaInicial,
          mesCompetenciaFinal: mesCompetenciaFinal,
          anoCompetenciaFinal: anoCompetenciaFinal,
          tipoIndice: 'igpm',
        }

        if (filtroValidado) {
          useJwt
            .post('cadastro/instituto/GetTaxas', param)
            .then((response) => {
              if (response.data.length > 0) {
                this.items = response.data
                this.totalRows = response.data.length
              } else this.$message.error('Não foram encontrados nenhum índice!')
              this.isBusy = false
            })
            .catch((error) => {
              console.error(error)
              this.isBusy = false
            })
        } else {
          this.$message.error('O filtro para pesquisa está incorreto!')
          this.isBusy = false
        }
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.modoAutenticacao == 'salvarIndice') this.salvarIndice()
          if (this.modoAutenticacao == 'importacao') this.apresentaOpcoesIndice()
        }
      },
      validaFiltros(mesIni, anoIni, mesFim, anoFim) {
        let validado = true

        if (mesIni) if (mesIni > 12 || mesIni < 0) validado = false

        if (mesFim) if (mesFim > 12 || mesFim < 0) validado = false

        if (anoIni) if (anoIni > 2100 || anoIni < 1900) validado = false

        if (anoFim) if (anoFim > 2100 || anoFim < 1900) validado = false

        if (validado) {
          if (mesIni && anoIni && mesFim && anoFim) {
            let dataInicialFiltro = new Date(String(anoIni) + '/' + String(mesIni) + '/' + '01')
            let dataFinalFiltro = new Date(String(anoFim) + '/' + String(mesFim) + '/' + '01')
            if (dataInicialFiltro > dataFinalFiltro) validado = false
          }
        }

        if (!mesIni && anoIni) validado = false
        if (mesIni && !anoIni) validado = false
        if (!mesFim && anoFim) validado = false
        if (mesFim && !anoFim) validado = false

        if (!mesIni && !anoIni && !mesFim && !anoFim) validado = true

        return validado
      },
      apresentaModalCadastro() {
        this.dataUpdate = {}
        this.carregando = false
        this.$refs['modal-cadastro-igpm'].show()
      },
      editarCadastro(item) {
        this.dataUpdate = { ...item }
        this.carregando = false
        this.$refs['modal-cadastro-igpm'].show()
      },
      autenticarSalvar() {
        this.modoAutenticacao = 'salvarIndice'
        this.$refs['modal-auth-senha'].show()
      },
      salvarIndice() {
        this.carregando = true
        this.$refs['formCadastroIGPM'].salvar()
      },
      fecharModalCadastro() {
        this.$refs['modal-cadastro-igpm'].hide()
      },
      montaAnoOpcoes() {
        let anoAtual = new Date().getFullYear()
        let anoInicial = new Date().getFullYear() - 2
        let anoOpcoes = []
        for (let i = anoAtual; i >= anoInicial; i--) {
          anoOpcoes.push({ value: i, text: i })
        }
        this.anoOpcoes = anoOpcoes
      },
    },
  }
</script>
