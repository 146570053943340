<template>
  <div>
    <b-card>
      <validation-observer ref="form">
        <b-form>
          <b-row md="12" class="mb-2">
            <b-col md="12">
              <h2>Documentos e validações</h2>
            </b-col>
            <b-col md="auto">
              <b-form-group
                label="Habilitar Biometria Facial"
                label-for="habilitaAplicativoReconhecimentoFacial"
                v-b-tooltip.hover.bottom="'Habilita o reconhecimento facial para propostas externas'"
              >
                <validation-provider #default="{ errors }" name="Habilita Biometria Facial">
                  <b-form-checkbox
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaAplicativoReconhecimentoFacial"
                    name="habilitaAplicativoReconhecimentoFacial"
                    id="habilitaAplicativoReconhecimentoFacial"
                    :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaAplicativoReconhecimentoFacial ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-form-group
                label="Habilitar Documento com Foto"
                label-for="habilitaAplicativoReconhecimentoDocumento"
                v-b-tooltip.hover.bottom="'Habilita o envio de documento com foto para propostas externas'"
              >
                <validation-provider #default="{ errors }" name="Habilita Documento com Foto">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaAplicativoReconhecimentoDocumento"
                    name="habilitaAplicativoReconhecimentoDocumento"
                    id="habilitaAplicativoReconhecimentoDocumento"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaAplicativoReconhecimentoDocumento ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-form-group
                label="Habilitar assinatura do contrato via SMS"
                label-for="habilitaAplicativoAssinaturaContratoSMS"
                v-b-tooltip.hover.bottom="'Habilita a assinatura do contrato via SMS'"
              >
                <validation-provider #default="{ errors }" name="Habilitar assinatura do contrato via SMS">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaAplicativoAssinaturaContratoSMS"
                    name="habilitaAplicativoAssinaturaContratoSMS"
                    id="habilitaAplicativoAssinaturaContratoSMS"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaAplicativoAssinaturaContratoSMS ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row md="12" class="mb-5">
            <b-col md="12">
              <h2>Termos de Aceite</h2>
            </b-col>
            <b-col md="5" style="margin-left: 1rem">
              <b-row>
                <b-form-group label="Habilitar Política de Privacidade" label-for="habilitaAplicativoTermosCondicoes">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
                    v-b-tooltip.hover.bottom="'Habilitar Política de Privacidade'"
                    v-model="optionsLocal.habilitaAplicativoPoliticaPrivacidade"
                    checked="false"
                    class="custom-control-primary"
                    name="Texto Aplicativo Política Privacidade"
                    id="habilitaAplicativoPoliticaPrivacidade"
                    switch
                    inline
                    style="margin-right: 0.3rem"
                  >
                    {{ optionsLocal.habilitaAplicativoPoliticaPrivacidade ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  v-if="optionsLocal.habilitaAplicativoPoliticaPrivacidade"
                  label="Texto da Política de Privacidade"
                  label-for="text"
                >
                  <validation-provider #default="{ errors }" name="Habilitar Política de Privacidade">
                    <vue-editor
                      id="text"
                      name="text"
                      rows="3"
                      placeholder="Texto Política de Privacidade"
                      v-model="optionsLocal.TextoAplicativoPoliticaPrivacidade"
                      style="height: 30vh !important; width: 100% !important"
                    ></vue-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-col md="5" style="margin-left: 1rem">
              <b-row>
                <b-form-group label="Habilitar Termos e Condições" label-for="habilitaAplicativoTermosCondicoes">
                  <b-form-checkbox
                    :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
                    v-b-tooltip.hover.bottom="'Habilitar Termos e Condições'"
                    v-model="optionsLocal.habilitaAplicativoTermosCondicoes"
                    checked="false"
                    class="custom-control-primary"
                    name="Texto Aplicativo Política Privacidade"
                    id="habilitaAplicativoTermosCondicoes"
                    switch
                    inline
                    style="margin-right: 0.3rem"
                  >
                    {{ optionsLocal.habilitaAplicativoTermosCondicoes ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  v-if="optionsLocal.habilitaAplicativoTermosCondicoes"
                  label="Texto dos Termos e Condições"
                  label-for="text"
                >
                  <validation-provider #default="{ errors }" name="Habilitar Termos e Condições">
                    <vue-editor
                      id="text"
                      name="text"
                      rows="3"
                      placeholder="Texto Termos e Condições"
                      v-model="optionsLocal.TextoAplicativoTermosCondicoes"
                      style="height: 30vh !important; width: 100% !important"
                    ></vue-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <b-row md="12" style="margin-left: 0.1rem; margin-top: 5rem">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="width: 160px"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="authESalve"
              :disabled="!acesso.ConfiguracoesSistemaAplicativoEditar"
            >
              {{ !isBusy ? 'Salvar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="width: 160px"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="limpar"
              :disabled="!acesso.ConfiguracoesSistemaPoliticaSenhasEditar"
            >
              Limpar
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { updateEmprestimosConsignados } from '@/utils/services/instituto'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../error/nao-autorizado-modal.vue'
  import Ripple from 'vue-ripple-directive'
  import { VueEditor } from 'vue2-editor'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      NaoAutorizadoModal,
      VueEditor,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        mode: null,
        optionsLocal: this.generalData,
        isBusy: false,
        isCollapseVisibleTermosCondicoes: false,
        isCollapseVisiblePoliticaPrivacidade: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          ConfiguracoesSistemaAplicativoEditar: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaAplicativoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaAplicativoEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaAplicativoEditar
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          ...this.optionsLocal,
          habilitaAplicativoReconhecimentoFacial: null,
          habilitaAplicativoReconhecimentoDocumento: null,
          habilitaAplicativoAssinaturaContratoSMS: null,
          habilitaAplicativoTermosCondicoes: null,
          TextoAplicativoTermosCondicoes: null,
          habilitaAplicativoPoliticaPrivacidade: null,
          TextoAplicativoPoliticaPrivacidade: null,
        }
      },
      salvar() {
        this.isBusy = true
        const parametro = {
          ...this.optionsLocal,
          habilitaAplicativoReconhecimentoFacial: this.optionsLocal.habilitaAplicativoReconhecimentoFacial,
          habilitaAplicativoReconhecimentoDocumento: this.optionsLocal.habilitaAplicativoReconhecimentoDocumento,
          habilitaAplicativoAssinaturaContratoSMS: this.optionsLocal.habilitaAplicativoAssinaturaContratoSMS,
          habilitaAplicativoTermosCondicoes: this.optionsLocal.habilitaAplicativoTermosCondicoes,
          TextoAplicativoTermosCondicoes: this.optionsLocal.TextoAplicativoTermosCondicoes,
          habilitaAplicativoPoliticaPrivacidade: this.optionsLocal.habilitaAplicativoPoliticaPrivacidade,
          TextoAplicativoPoliticaPrivacidade: this.optionsLocal.TextoAplicativoPoliticaPrivacidade,
        }

        updateEmprestimosConsignados(this.optionsLocal.id, parametro)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              this.$router.go()
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao salvar os parametros do aplicativo')
          })
      },
      authESalve() {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      toggleCollapse(opcao) {
        if (opcao == 'termosCondicoes') {
          this.isCollapseVisibleTermosCondicoes = !this.isCollapseVisibleTermosCondicoes
        } else if (opcao == 'politicaPrivacidade') {
          this.isCollapseVisiblePoliticaPrivacidade = !this.isCollapseVisiblePoliticaPrivacidade
        }
      },
    },
    watch: {
      'optionsLocal.habilitaAplicativoPoliticaPrivacidade'(newVal) {
        if (!newVal) {
          this.isCollapseVisiblePoliticaPrivacidade = false
        }
      },
      'optionsLocal.habilitaAplicativoTermosCondicoes'(newVal) {
        if (!newVal) {
          this.isCollapseVisibleTermosCondicoes = false
        }
      },
    },
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }
</style>
