var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formRestricoes"},[_c('b-form',[_c('b-card-text',{staticClass:"mb-1"},[_c('small',{staticClass:"textoObservacao"},[_vm._v(" Para mais informações do parâmetro, passe o mouse por cima dele. ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Seleciona os tipos de cálculo que serão utilizados na geração de propostas!'),expression:"'Seleciona os tipos de cálculo que serão utilizados na geração de propostas!'",modifiers:{"hover":true,"right":true}}],attrs:{"label":"Tipo de Cálculo","label-for":"tipoCalculo"}},[_c('validation-provider',{attrs:{"name":"Tipo de Cálculo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('v-select-pt',{staticClass:"w-100",attrs:{"multiple":"","id":"tipoCalculo","name":"tipoCalculo","options":_vm.tipoCalculoOpcoes,"reduce":function (options) { return options.value; },"placeholder":"Selecione:","label":"text","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},model:{value:(_vm.tipoCalculo),callback:function ($$v) {_vm.tipoCalculo=$$v},expression:"tipoCalculo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Parametriza a margem consignável que será utilizada na geração de propostas!'),expression:"'Parametriza a margem consignável que será utilizada na geração de propostas!'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Margem Consignável (em %)","label-for":"margemConsignavel"}},[_c('validation-provider',{attrs:{"name":"Margem Consignável","rules":"required|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"id":"margemConsignavel","state":errors.length > 0 ? false : null,"placeholder":"Margem Consignável (em %)","name":"Margem Consignável (em %)","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},model:{value:(_vm.optionsLocal.margemConsignavel),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "margemConsignavel", $$v)},expression:"optionsLocal.margemConsignavel"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.tipoCalculo.includes('posFixado'))?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Parametriza o limitador de margem que será utilizado na geração de propostas!'),expression:"'Parametriza o limitador de margem que será utilizado na geração de propostas!'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Limitador Margem (em %)","label-for":"limitadorMargem"}},[_c('validation-provider',{attrs:{"name":"Limitador Margem","rules":"required|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"id":"limitadorMargem","state":errors.length > 0 ? false : null,"placeholder":"Limitador Margem","name":"Limitador Margem","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},model:{value:(_vm.optionsLocal.limitadorMargem),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limitadorMargem", $$v)},expression:"optionsLocal.limitadorMargem"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1621738475)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(
              'O parâmetro habilita a utilização de Float Financeiro na aprovação das propostas onde o cálculo leva em conta o tempo entre a aprovação do contrato e o repasse da primeira parcela, acrescido de um ajuste de 2 dias. Esta métrica é essencial para determinar o valor adicional do float, que será incorporado ao saldo devedor inicial e amortizado durante o período do contrato.'
            ),expression:"\n              'O parâmetro habilita a utilização de Float Financeiro na aprovação das propostas onde o cálculo leva em conta o tempo entre a aprovação do contrato e o repasse da primeira parcela, acrescido de um ajuste de 2 dias. Esta métrica é essencial para determinar o valor adicional do float, que será incorporado ao saldo devedor inicial e amortizado durante o período do contrato.'\n            ",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Utiliza Taxa de Float","label-for":"utilizaFloatFinanceiro"}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"utilizaFloatFinanceiro","name":"utilizaFloatFinanceiro","button-variant":"primary","options":[
                { value: true, text: 'Sim' },
                { value: false, text: 'Não' } ]},model:{value:(_vm.optionsLocal.utilizaFloatFinanceiro),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "utilizaFloatFinanceiro", $$v)},expression:"optionsLocal.utilizaFloatFinanceiro"}})],1)],1),(_vm.userData.role == 'master' || _vm.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF)?_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Parâmetro habilita a taxa de IOF na geração de propostas!'),expression:"'Parâmetro habilita a taxa de IOF na geração de propostas!'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Utiliza Taxa de IOF","label-for":"utilizaIOF"}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"utilizaIOF","name":"utilizaIOF","button-variant":"primary","options":[
                { value: true, text: 'Sim' },
                { value: false, text: 'Não' } ]},model:{value:(_vm.optionsLocal.utilizaIOF),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "utilizaIOF", $$v)},expression:"optionsLocal.utilizaIOF"}})],1)],1):_vm._e(),(
            _vm.optionsLocal.utilizaIOF &&
            (_vm.userData.role == 'master' || _vm.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF)
          )?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Parametrize a alíquota efetiva do IOF que será utilizada na geração de propostas!'),expression:"'Parametrize a alíquota efetiva do IOF que será utilizada na geração de propostas!'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Alíquota Efetiva IOF","label-for":"aliquotaEfetivaIOF"}},[_c('validation-provider',{attrs:{"name":"Alíquota Efetiva IOF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"aliquotaEfetivaIOF","state":errors.length > 0 ? false : null,"placeholder":"Alíquota Efetiva IOF","name":"Aliquota Efetiva"},model:{value:(_vm.optionsLocal.aliquotaEfetivaIOF),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "aliquotaEfetivaIOF", $$v)},expression:"optionsLocal.aliquotaEfetivaIOF"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2755102899)})],1)],1):_vm._e(),(
            _vm.optionsLocal.utilizaIOF &&
            (_vm.userData.role == 'master' || _vm.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF)
          )?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Parametrize a alíquota Diária do IOF que será utilizada na geração de propostas!'),expression:"'Parametrize a alíquota Diária do IOF que será utilizada na geração de propostas!'",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Alíquota Diária IOF","label-for":"aliquotaDiariaIOF"}},[_c('validation-provider',{attrs:{"name":"Alíquota Diária IOF","rules":"required|maior_decimal:3|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('Cleave',{class:['form-control', { 'is-invalid': errors.length > 0 }],attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"aliquotaDiariaIOF","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Alíquota Diária IOF","name":"Aliquota Diária","raw":false,"options":_vm.options.cleaveAliqutoDiariaIOF},model:{value:(_vm.optionsLocal.aliquotaDiariaIOF),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "aliquotaDiariaIOF", $$v)},expression:"optionsLocal.aliquotaDiariaIOF"}},[(errors.length)?_c('i',{staticClass:"fas fa-exclamation-circle"}):_vm._e()]),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4068039191)})],1)],1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }