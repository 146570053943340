var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formRestricoes"},[_c('b-form',[_c('b-card-text',{staticClass:"mb-1"},[_c('small',{staticClass:"textoObservacao"},[_vm._v(" Para mais informações do parâmetro, passe o mouse por cima dele. ")])]),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Limitador de Idade (em anos)","label-for":"limitadorIdade"}},[_c('validation-provider',{attrs:{"name":"Limitador de Idade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limitadorIdade","state":errors.length > 0 ? false : null,"placeholder":"Limitador de Idade (70 anos)"},model:{value:(_vm.optionsLocal.limitadorIdade),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limitadorIdade", $$v)},expression:"optionsLocal.limitadorIdade"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Limite de Parcelas (quantidade)","label-for":"limiteParcelas"}},[_c('validation-provider',{attrs:{"name":"Limite de Parcelas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limiteParcelas","state":errors.length > 0 ? false : null,"placeholder":"Limite de Parcelas"},model:{value:(_vm.optionsLocal.limiteParcelas),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limiteParcelas", $$v)},expression:"optionsLocal.limiteParcelas"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Período de carência (em dias)","label-for":"tempocarencia"}},[_c('validation-provider',{attrs:{"name":"Período de carência (em dias)","rules":"required|integer|between:1,999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"tempoCarencia","state":errors.length > 0 ? false : null,"placeholder":"Período de carência (em dias)"},model:{value:(_vm.optionsLocal.tempoCarencia),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "tempoCarencia", $$v)},expression:"optionsLocal.tempoCarencia"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Mês de vencimento da 1º Parcela","label-for":"mesVencimento"}},[_c('validation-provider',{attrs:{"name":"Mês de vencimento da 1º Parcela","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"name":"mesVencimento","options":_vm.mesVencimentoOpcoes},model:{value:(_vm.optionsLocal.mesVencimento),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "mesVencimento", $$v)},expression:"optionsLocal.mesVencimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('small',{staticClass:"text-primary d-block"},[_vm._v("Quantos meses até o vencimento da primeira parcela, contabilizado a partir do mês da simulação")])],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Parcelas em Atraso","label-for":"parcelasEmAtrasoParaDivida"}},[_c('validation-provider',{attrs:{"name":"Parcelas em Atraso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"bloqueioCaractereMask",rawName:"v-bloqueioCaractereMask",value:({ tamanho: 3, regex: /[^\d]/ }),expression:"{ tamanho: 3, regex: /[^\\d]/ }"}],staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"parcelasEmAtrasoParaDivida","name":"parcelasEmAtrasoParaDivida","placeholder":"Parcelas em Atraso"},model:{value:(_vm.optionsLocal.parcelasEmAtrasoParaDivida),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "parcelasEmAtrasoParaDivida", $$v)},expression:"optionsLocal.parcelasEmAtrasoParaDivida"}}),_c('b-tooltip',{attrs:{"placement":"bottom","target":"parcelasEmAtrasoParaDivida","noninteractive":""}},[_vm._v("Quantas parcelas atrasadas são necessárias para considerar um contrato como dívida")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }