var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formIndiceIGPM"},[_c('b-form',[_c('b-row',{staticClass:"p-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ano","label-for":"ano"}},[_c('validation-provider',{attrs:{"name":"Ano","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select-pt',{attrs:{"name":"ano","options":_vm.anoOpcoes,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.dados.ano),callback:function ($$v) {_vm.$set(_vm.dados, "ano", $$v)},expression:"dados.ano"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Mês","label-for":"mes"}},[_c('validation-provider',{attrs:{"name":"Mês","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select-pt',{attrs:{"name":"mes","options":_vm.mesOpcoes,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.dados.mes),callback:function ($$v) {_vm.$set(_vm.dados, "mes", $$v)},expression:"dados.mes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Taxa Mensal","label-for":"taxaMensal"}},[_c('validation-provider',{attrs:{"name":"Taxa Mensal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"form-control-merge",attrs:{"type":"text","id":"taxaMensal","state":errors.length > 0 ? false : null,"name":"Taxa Mensal","placeholder":"Taxa Mensal"},model:{value:(_vm.dados.taxaMensal),callback:function ($$v) {_vm.$set(_vm.dados, "taxaMensal", $$v)},expression:"dados.taxaMensal"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Taxa Acumulada","label-for":"taxaAcumulada"}},[_c('validation-provider',{attrs:{"name":"Taxa Acumulada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"form-control-merge",attrs:{"type":"text","id":"taxaAcumulada","state":errors.length > 0 ? false : null,"name":"Taxa Acumulada","placeholder":"Taxa Acumulada"},model:{value:(_vm.dados.taxaAcumulada),callback:function ($$v) {_vm.$set(_vm.dados, "taxaAcumulada", $$v)},expression:"dados.taxaAcumulada"}}),_c('b-input-group-append',[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }