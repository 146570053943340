import { fetcherFactory } from '@/client/api/fetcher'
import { AxiosResponse } from 'axios'
import { InstitutoApiFactory } from '@/client/api/clients/instituto-api'
import { jwtDecode } from 'jwt-decode'

// models
import {
  RestricoesTipoCalculoDto,
  RestricoesRenegociacaoDto,
  GrupoAcesso,
  Instituto,
  PutRecursos,
  Recursos,
  RestricoesEmprestimosConsignadosDto,
} from '@/client/api/models'
import { RestricoesApiFactory } from '@/client/api/clients/restricoes-api'

import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'

const updateGrupoAcesso = (data: GrupoAcesso) => {
  const userData = jwtDecode(localStorage.getItem('userData'))['userData'] as any
  userData.grupoAcesso = data
  cryptUserData(userData)
}
const updateInstitutoData = (data: Instituto) => {
  const userData = jwtDecode(localStorage.getItem('userData'))['userData'] as any
  userData.Instituto = data
  cryptUserData(userData)
}

/**
 * @url put /cadastro/instituto/{id}
 * @return Instituto
 * @description Atualiza os dados do instituto
 * @param id
 * @param Instituto
 */
export const updateTaxasPosFixado = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putTaxasPosFixado(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/taxas/{id}
 * @description Atualiza as taxas do instituto
 * @return Instituto
 */
export const updateTaxas = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putTaxas(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/parametroGrpe/{id}
 */
export const updateParametroGrpe = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putParametroGrpe(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/parametroBoleto/{id}
 * @return Instituto
 * @description Atualiza os parâmetros de boleto
 */
export const updateParametrosBoleto = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putParametroBoleto(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/parametroRenegociacao/{id}
 * @return Instituto
 * @description Atualiza os parâmetros de renegociação
 */
export const updateParametrosRenegociacao = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putParametroRenegociacao(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/personalizacoes/{id}
 * @return Instituto
 */
export const updatePersonalizacoes = async (
  id: number,
  data: Instituto,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await InstitutoFactory.putPersonalizacoes(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /cadastro/instituto/putRecursos/{id}
 * @return Recursos
 */
export const updateRecursos = async (
  id: number,
  data: PutRecursos,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<Recursos>> => {
  const response = await InstitutoFactory.putRecursos(id, data)
  return response
}

/**
 * @url put /cadastro/instituto/putGrupoCadastro/{id}
 */
export const updateGrupoCadastro = async (
  id: number,
  data: GrupoAcesso,
  InstitutoFactory = fetcherFactory(InstitutoApiFactory),
): Promise<AxiosResponse<GrupoAcesso>> => {
  const response = await InstitutoFactory.putGrupoCadastro(id, data)
  updateGrupoAcesso(response.data)
  return response
}

/**
 * @url put /instituto/restricoes/calculadoraExterna/{id}
 */
export const updateCalculadoraExterna = async (
  id: number,
  data: Instituto,
  RestricoesFactory = fetcherFactory(RestricoesApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await RestricoesFactory.calculadoraExterna(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /instituto/restricoes/renegociacao/{id}
 * @description Atualiza as restrições de renegociação
 * @return Instituto
 * @param id
 * @param RestricoesRenegociacaoDto
 */
export const updateRestricoesRenegociacao = async (
  id: number,
  data: RestricoesRenegociacaoDto,
  RestricoesFactory = fetcherFactory(RestricoesApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await RestricoesFactory.renegociacao(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /instituto/restricoes/tipoCalculo/{id}
 * @description Atualiza as restrições de tipo de cálculo
 * @return Instituto
 * @param id
 * @param RestricoesTipoCalculoDto
 */
export const updateRestricoesTipoCalculo = async (
  id: number,
  data: RestricoesTipoCalculoDto,
  RestricoesFactory = fetcherFactory(RestricoesApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await RestricoesFactory.tipoCalculo(id, data)
  updateInstitutoData(response.data)
  return response
}

/**
 * @url put /instituto/restricoes/emprestimosConsignados/{id}
 * @return Instituto
 * @description Atualiza as restrições de empréstimos consignados
 */
export const updateEmprestimosConsignados = async (
  id: number,
  data: RestricoesEmprestimosConsignadosDto,
  RestricoesFactory = fetcherFactory(RestricoesApiFactory),
): Promise<AxiosResponse<Instituto>> => {
  const response = await RestricoesFactory.emprestimosConsignados(id, data)
  updateInstitutoData(response.data)
  return response
}
