<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-card-text class="mb-1">
          <small class="textoObservacao"> Para mais informações do parâmetro, passe o mouse por cima dele. </small>
        </b-card-text>
        <b-row>
          <!-- LIMITADOR DE IDADE -->
          <b-col sm="2">
            <b-form-group label="Limitador de Idade (em anos)" label-for="limitadorIdade">
              <validation-provider #default="{ errors }" name="Limitador de Idade" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limitadorIdade"
                    v-model="optionsLocal.limitadorIdade"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limitador de Idade (70 anos)"
                    v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Limite de Parcelas -->
          <b-col sm="3">
            <b-form-group label="Limite de Parcelas (quantidade)" label-for="limiteParcelas">
              <validation-provider #default="{ errors }" name="Limite de Parcelas" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="limiteParcelas"
                    v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                    v-model="optionsLocal.limiteParcelas"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limite de Parcelas"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Periodo de carência -->
          <b-col sm="2">
            <b-form-group label="Período de carência (em dias)" label-for="tempocarencia">
              <validation-provider
                #default="{ errors }"
                name="Período de carência (em dias)"
                rules="required|integer|between:1,999"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="tempoCarencia"
                    v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                    v-model="optionsLocal.tempoCarencia"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Período de carência (em dias)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Mes Vencimento -->
          <b-col md="3">
            <b-form-group label="Mês de vencimento da 1º Parcela" label-for="mesVencimento">
              <validation-provider #default="{ errors }" name="Mês de vencimento da 1º Parcela" rules="required">
                <b-form-select
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  name="mesVencimento"
                  v-model="optionsLocal.mesVencimento"
                  :options="mesVencimentoOpcoes"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <small class="text-primary d-block"
                >Quantos meses até o vencimento da primeira parcela, contabilizado a partir do mês da simulação</small
              >
            </b-form-group>
          </b-col>

          <!-- Parcelas em Atraso -->
          <b-col md="2">
            <b-form-group label="Parcelas em Atraso" label-for="parcelasEmAtrasoParaDivida">
              <validation-provider #default="{ errors }" name="Parcelas em Atraso" rules="required">
                <b-form-input
                  :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  id="parcelasEmAtrasoParaDivida"
                  name="parcelasEmAtrasoParaDivida"
                  v-model="optionsLocal.parcelasEmAtrasoParaDivida"
                  class="form-control-merge"
                  placeholder="Parcelas em Atraso"
                  v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                />
                <b-tooltip placement="bottom" target="parcelasEmAtrasoParaDivida" noninteractive
                  >Quantas parcelas atrasadas são necessárias para considerar um contrato como dívida</b-tooltip
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

  import { updateRestricoesRenegociacao } from '@/utils/services/instituto'
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import { formatarParaNumber } from '@/libs/utils'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../../error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCardActions,
      AutenticaSenha,
      ValidationObserver,
      ValidationProvider,
      required,
      NaoAutorizadoModal,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: this.generalData ?? {
          limitadorIdade: null,
          limiteParcelas: null,
          tempoCarencia: null,
          mesVencimento: null,
          margemConsignavel: null,
        },
        anoOpcao: [],
        pesquisar: {
          mesOpcoes: 1,
          anoOpcao: '',
          perPage: 25,
          currentPage: 1,
        },
        mesVencimentoOpcoes: [
          { value: 0, text: '0' },
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' },
        ],
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        //Padrão modal
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [25, 50, 100],
        pesquisar: { perPage: 25, currentPage: 1 },
        striped: true,
        items: [],
        acesso: {
          ConfiguracoesSistemaRestricaoEditar: false,
        },
      }
    },
    computed: {},
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoEditar
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          ...this.optionsLocal,
          limitadorIdade: null,
          limiteParcelas: null,
          tempoCarencia: null,
          mesVencimento: null,
          margemConsignavel: null,
          margemConsignavel: null,
          margemConsignavel: null,
          parcelasEmAtrasoParaDivida: null,
        }
        this.$refs.formRestricoes.reset()
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (!this.optionsLocal.id) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          const aux = {
            ...this.optionsLocal,
            limitadorIdade: formatarParaNumber(this.optionsLocal.limitadorIdade),
            limiteParcelas: formatarParaNumber(this.optionsLocal.limiteParcelas),
            tempoCarencia: formatarParaNumber(this.optionsLocal.tempoCarencia),
            margemConsignavel: formatarParaNumber(this.optionsLocal.margemConsignavel),
            parcelasEmAtrasoParaDivida: formatarParaNumber(this.optionsLocal.parcelasEmAtrasoParaDivida),
            mesVencimento: this.optionsLocal.mesVencimento,
          }
          updateRestricoesRenegociacao(this.optionsLocal.id, aux)
            .then(() => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch((error) => {
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erro: ' + error.response.data.msg,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        }
      },
    },
  }
</script>

<style lang="scss"></style>
