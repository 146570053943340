<template>
  <div>
    <b-tabs v-show="this.userData.institutoSelecionado" pills>
      <!--Parâmetros de Sistema-->
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Parâmetros do Sistema</span>
        </template>

        <b-tabs v-show="this.userData.institutoSelecionado">
          <b-tab active v-if="acesso.ConfiguracoesSistemaCompTaxas">
            <template #title>
              <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Composição de Taxa</span>
            </template>
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">Tabela SAC / PRICE</span>
                </template>
                <ConfiguracoesSistemaComposicaoTaxa
                  ref="ConfiguracoesSistemaComposicaoTaxa"
                  v-if="options"
                  :general-data="options"
                />
              </b-tab>
              <b-tab v-if="options.tipoCalculoPosFixado">
                <template #title>
                  <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
                  <span class="font-weight-bold">Tabela Pós-Fixado</span>
                </template>
                <ConfiguracoesTabelaPosFixado v-if="options" :general-data="options" />
              </b-tab>
            </b-tabs>
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaIndiceRef">
            <template #title>
              <feather-icon icon="TrendingUpIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Índices</span>
            </template>
            <ConfiguracoesSistemaIndices v-if="options" :general-data="options" @atualizarIndice="atualizarIndice" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaRestricaoRPPS">
            <template #title>
              <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Recursos do RPPS</span>
            </template>
            <ConfiguracoesSistemaRecursos v-if="options" :general-data="options" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaRestricao">
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Restrições</span>
            </template>
            <ConfiguracoesSistemaRestricoes v-if="options" :general-data="options" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaParametroArrecadacao">
            <template #title>
              <feather-icon icon="ToolIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Parâmetros</span>
            </template>
            <ConfiguracoesSistemaParametros v-if="options" :general-data="options" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaCriteriosElegibilidade">
            <template #title>
              <feather-icon icon="UsersIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Critérios de Elegibilidade</span>
            </template>
            <ConfiguracoesSistemaCriteriosElegibilidade v-if="options" :general-data="options" />
          </b-tab>
        </b-tabs>
      </b-tab>

      <!--Personalização de Sistema-->
      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Personalização do Sistema</span>
        </template>
        <b-tabs v-show="this.userData.institutoSelecionado">
          <b-tab>
            <template #title>
              <feather-icon icon="EditIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Personalização</span>
            </template>
            <ConfiguracoesSistemaPersonalizacao v-if="options" :general-data="options" />
          </b-tab>
          <b-tab v-if="acesso.ConfiguracoesSistemaNotificacoes">
            <template #title>
              <feather-icon icon="BellIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Notificações</span>
            </template>
            <ConfiguracoesSistemaNotificacoes v-if="options" :general-data="options" />
          </b-tab>
          <b-tab v-if="userData.role == 'master'">
            <template #title>
              <feather-icon icon="UploadCloudIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Integrações</span>
            </template>
            <ConfiguracoesSistemaIntagracoes v-if="options" :general-data="options" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaAplicativo">
            <template #title>
              <feather-icon icon="SmartphoneIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Aplicativo</span>
            </template>
            <ConfiguracoesSistemaAplicativo v-if="options" :general-data="options" />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="SquareIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Totem</span>
            </template>
            <ConfiguracoesSistemaTotem v-if="options" :general-data="options" />
          </b-tab>

          <b-tab v-if="acesso.ConfiguracoesSistemaPoliticaSenhas">
            <template #title>
              <feather-icon icon="KeyIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Política de senhas</span>
            </template>
            <ConfiguracoesSistemaPoliticaSenha v-if="options" :general-data="options" />
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ConfiguracoesSistemaComposicaoTaxa from './ConfiguracoesSistemaComposicaoTaxa.vue'
  import ConfiguracoesSistemaIndices from './ConfiguracoesSistemaIndices/ConfiguracoesSistemaIndices.vue'
  import ConfiguracoesSistemaRecursos from './ConfiguracoesSistemaRecursos/ConfiguracoesSistemaRecursos.vue'
  import ConfiguracoesSistemaRestricoes from './ConfiguracoesSistemaRestricoes/ConfiguracoesSistemaRestricoes.vue'
  import ConfiguracoesSistemaPersonalizacao from './ConfiguracoesSistemaPersonalizacao.vue'
  import ConfiguracoesSistemaNotificacoes from './ConfiguracoesSistemaNotificacoes.vue'
  import ConfiguracoesSistemaCriteriosElegibilidade from './ConfiguracoesSistemaCriteriosElegibilidade/ConfiguracoesSistemaCriteriosElegibilidade.vue'
  import ConfiguracoesSistemaAplicativo from './ConfiguracoesSistemaAplicativo.vue'
  import ConfiguracoesSistemaPoliticaSenha from './ConfiguracoesSistemaPoliticaSenha.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import ConfiguracoesSistemaParametros from './ConfiguracoesSistemaParametros/ConfiguracoesSistemaParametros.vue'
  import ParametrosArrecadacao from './ConfiguracoesSistemaParametros/ParametrosArrecadacao.vue'
  import useJwt from '@/auth/jwt/useJwt'
  import { required } from '@validations'
  import ConfiguracoesTabelaPosFixado from './configuracoes-tabela-pos-fixado/ConfiguracoesTabelaPosFixado.vue'
  import ConfiguracoesSistemaIntagracoes from './ConfiguracoesSistemaIntagracoes.vue'
  import ConfiguracoesSistemaTotem from './ConfiguracoesSistemaTotem.vue'

  export default {
    components: {
      ConfiguracoesTabelaPosFixado,
      ConfiguracoesSistemaComposicaoTaxa,
      ConfiguracoesSistemaIndices,
      ConfiguracoesSistemaRecursos,
      ConfiguracoesSistemaRestricoes,
      ParametrosArrecadacao,
      ConfiguracoesSistemaPersonalizacao,
      ConfiguracoesSistemaNotificacoes,
      ConfiguracoesSistemaCriteriosElegibilidade,
      ConfiguracoesSistemaAplicativo,
      ValidationObserver,
      ValidationProvider,
      required,
      ConfiguracoesSistemaParametros,
      ConfiguracoesSistemaIntagracoes,
      ConfiguracoesSistemaPoliticaSenha,
      ConfiguracoesSistemaTotem,
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        tipoIndice: '',
        acesso: {
          ConfiguracoesSistemaNotificacoes: false,
          ConfiguracoesSistemaCompTaxas: false,
          ConfiguracoesSistemaParametroArrecadacao: false,
          ConfiguracoesSistemaCriteriosElegibilidade: false,
          ConfiguracoesSistemaPerfil: false,
          ConfiguracoesSistemaPersonalizacao: false,
          ConfiguracoesSistemaIndiceRef: false,
          ConfiguracoesSistemaRestricao: false,
          ConfiguracoesSistemaRestricaoRPPS: false,
          ConfiguracoesSistemaAplicativo: false,
          ConfiguracoesSistemaPoliticaSenhas: false,
        },
      }
    },
    async mounted() {
      await this.carregarGrid()

      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaNotificacoes = true
        this.acesso.ConfiguracoesSistemaCompTaxas = true
        this.acesso.ConfiguracoesSistemaParametroArrecadacao = true
        this.acesso.ConfiguracoesSistemaCriteriosElegibilidade = true
        this.acesso.ConfiguracoesSistemaPersonalizacao = true
        this.acesso.ConfiguracoesSistemaIndiceRef = true
        this.acesso.ConfiguracoesSistemaRestricao = true
        this.acesso.ConfiguracoesSistemaRestricaoRPPS = true
        this.acesso.ConfiguracoesSistemaAplicativo = true
        this.acesso.ConfiguracoesSistemaPoliticaSenhas = true
      } else {
        this.acesso.ConfiguracoesSistemaNotificacoes = this.userData.GrupoAcesso.ConfiguracoesSistemaNotificacoes
        this.acesso.ConfiguracoesSistemaCompTaxas = this.userData.GrupoAcesso.ConfiguracoesSistemaCompTaxas
        this.acesso.ConfiguracoesSistemaParametroArrecadacao =
          this.userData.GrupoAcesso.ConfiguracoesSistemaParametroArrecadacao
        this.acesso.ConfiguracoesSistemaCriteriosElegibilidade =
          this.userData.GrupoAcesso.ConfiguracoesSistemaCriteriosElegibilidade
        this.acesso.ConfiguracoesSistemaPersonalizacao = this.userData.GrupoAcesso.ConfiguracoesSistemaPersonalizacao
        this.acesso.ConfiguracoesSistemaIndiceRef = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRef
        this.acesso.ConfiguracoesSistemaRestricao = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricao
        this.acesso.ConfiguracoesSistemaRestricaoRPPS = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoRPPS
        this.acesso.ConfiguracoesSistemaAplicativo = this.userData.GrupoAcesso.ConfiguracoesSistemaAplicativo
        this.acesso.ConfiguracoesSistemaPoliticaSenhas = this.userData.GrupoAcesso.ConfiguracoesSistemaPoliticaSenhas
      }
    },
    methods: {
      async carregarGrid() {
        await useJwt
          .get(`cadastro/instituto/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.options = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      atualizarIndice(tipoIndice) {
        this.$refs.ConfiguracoesSistemaComposicaoTaxa.atualizaIndice(tipoIndice)
      },
    },
  }
</script>
