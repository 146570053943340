<template>
  <div>
    <b-card title="Notificações">
      <validation-observer ref="formNotificacao">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col md="12">
              <b-form-group label="Usuários" label-for="usuario">
                <v-select-pt
                  name="usuario"
                  v-model="optionsLocal.usuario"
                  :options="usuarioOpcao"
                  :reduce="(option) => option.id"
                  label="name"
                  multiple
                  :disabled="!acesso.ConfiguracoesSistemaNotificacoesEnviar"
                ></v-select-pt>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group label="Título" label-for="title">
                <b-form-textarea
                  id="title"
                  name="title"
                  size="lg"
                  placeholder="Texto da notificação"
                  v-model="optionsLocal.title"
                  :disabled="!acesso.ConfiguracoesSistemaNotificacoesEnviar"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group label="Texto" label-for="text">
                <b-form-textarea
                  id="text"
                  name="text"
                  rows="3"
                  placeholder="Texto da notificação"
                  v-model="optionsLocal.text"
                  :disabled="!acesso.ConfiguracoesSistemaNotificacoesEnviar"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="salvar"
                :disabled="!acesso.ConfiguracoesSistemaNotificacoesEnviar"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                @click.prevent="limpar"
                :disabled="!acesso.ConfiguracoesSistemaNotificacoesEnviar"
              >
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  //form referencia e valida
  import { ref } from '@vue/composition-api'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'

  // notificações
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ref,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        usuarioOpcao: [],
        acesso: {
          ConfiguracoesSistemaNotificacoesEnviar: false,
        },
      }
    },
    mounted() {
      this.carregarUsuario()

      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaNotificacoesEnviar = true
      } else {
        this.acesso.ConfiguracoesSistemaNotificacoesEnviar = this.userData.GrupoAcesso.ConfiguracoesSistemaNotificacoesEnviar
      }
    },
    methods: {
      carregarUsuario() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/user/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.usuarioOpcao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      limpar() {
        this.optionsLocal = {}
        this.optionsLocal.id = JSON.parse(JSON.stringify(this.generalData.id))
      },
      salvar() {
        this.$refs.formNotificacao.validate().then((success) => {
          if (success) {
            if (this.optionsLocal.id == undefined) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erro: O instituto a ser configurado não está sendo identificado, reinicie o sistema. ',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            } else {
              const data = {
                userId: this.optionsLocal.usuario,
                title: this.optionsLocal.title,
                text: this.optionsLocal.text,
              }
              useJwt
                .post(`utils/postNotificacoes`, data)
                .then((response) => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(() => {
                    this.limpar()
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Erro: ' + error.response.data.msg,
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
