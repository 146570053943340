








































import { defineComponent, ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default defineComponent({
  emits: {
    pesquisar: (data: { dataInicial: string; dataFinal: string }) => true,
  },
  setup(props, { emit }) {
    const cleaveDataPadrao = {
      date: true,
      delimiter: '/',
      datePattern: ['m', 'Y'],
    }

    const dataInicial = ref('')
    const dataFinal = ref('')

    const pesquisar = () => {
      emit('pesquisar', {
        dataInicial: dataInicial.value,
        dataFinal: dataFinal.value,
      })
    }

    return {
      pesquisar,
      dataInicial,
      dataFinal,
      cleaveDataPadrao,
    }
  },
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
})
