var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formRestricoes"},[_c('b-form',[_c('b-card-text',{staticClass:"mb-1"},[_c('small',{staticClass:"textoObservacao"},[_vm._v(" Para mais informações do parâmetro, passe o mouse por cima dele. ")])]),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Limitador de Idade (em anos)","label-for":"limitadorIdade"}},[_c('validation-provider',{attrs:{"name":"Limitador por Idade","rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limitadorIdade","state":errors.length > 0 ? false : null,"placeholder":"Limitador de Idade (70 anos)","type":"number"},model:{value:(_vm.optionsLocal.limitadorIdade),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limitadorIdade", _vm._n($$v))},expression:"optionsLocal.limitadorIdade"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Limite de Parcelas (quantidade)","label-for":"limiteParcelas"}},[_c('validation-provider',{attrs:{"name":"limiteParcelas","rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limiteParcelas","state":errors.length > 0 ? false : null,"placeholder":"Limite de Parcelas","type":"number"},model:{value:(_vm.optionsLocal.limiteParcelas),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limiteParcelas", _vm._n($$v))},expression:"optionsLocal.limiteParcelas"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"CAPAG","label-for":"capag"}},[_c('validation-provider',{attrs:{"name":"CAPAG","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"name":"capag","options":_vm.capagOpcoes},model:{value:(_vm.optionsLocal.capag),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "capag", _vm._n($$v))},expression:"optionsLocal.capag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Limite de Alocação (em %)","label-for":"limiteAlocacao"}},[_c('validation-provider',{attrs:{"name":"Limite de Alocação (em %)","rules":"required|valor_minimo_number:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limiteAlocacao","state":errors.length > 0 ? false : null,"placeholder":"Limite de Alocação (%)","type":"number"},model:{value:(_vm.optionsLocal.limiteAlocacao),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limiteAlocacao", _vm._n($$v))},expression:"optionsLocal.limiteAlocacao"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Mês de vencimento da 1º Parcela","label-for":"mesVencimento"}},[_c('validation-provider',{attrs:{"name":"Mês de vencimento da 1º Parcela","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"mesVencimento","name":"mesVencimento","options":_vm.mesVencimentoOpcoes},model:{value:(_vm.optionsLocal.mesVencimento),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "mesVencimento", $$v)},expression:"optionsLocal.mesVencimento"}}),_c('b-tooltip',{attrs:{"placement":"bottom","target":"mesVencimento","noninteractive":""}},[_vm._v("Quantos meses até o vencimento da primeira parcela, contabilizado a partir do mês da simulação")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor máximo por Empréstimos","label-for":"limitadorEmprestimos"}},[_c('validation-provider',{attrs:{"name":"Limitador de Empréstimos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"limitadorEmprestimos","name":"limitadorEmprestimos","options":_vm.limitadorEmprestimosOpcoes},model:{value:(_vm.optionsLocal.limitadorEmprestimos),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "limitadorEmprestimos", $$v)},expression:"optionsLocal.limitadorEmprestimos"}}),_c('b-tooltip',{attrs:{"placement":"bottom","target":"limitadorEmprestimos","noninteractive":""}},[_vm._v("Limita o valor máximo do empréstimo concedido com base na remuneração mensal do tomador")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(false)?_c('b-col',{attrs:{"md":"2","align-self":"center"}},[_c('b-form-group',{attrs:{"label":"Permitir Assinatura Digital/Eletrônica","label-for":"assinaturaDigital"}},[_c('span',[_vm._v("Não ")]),_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"assinaturaDigital","switch":"","inline":""},model:{value:(_vm.optionsLocal.assinaturaDigital),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "assinaturaDigital", $$v)},expression:"optionsLocal.assinaturaDigital"}}),_c('span',[_vm._v("Sim")])],1)],1):_vm._e(),(_vm.userData.role == 'master')?_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Ambiente de API","label-for":"ambienteApi"}},[_c('validation-provider',{attrs:{"name":"Ambiente de API","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"ambienteApi","name":"ambienteApi","options":_vm.ambientesOpcoes},model:{value:(_vm.optionsLocal.ambienteApi),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "ambienteApi", $$v)},expression:"optionsLocal.ambienteApi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1823650294)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Tipo de Vencimento de Parcelas","label-for":"tipoVencimentoParcela"}},[_c('validation-provider',{attrs:{"name":"Tipo de Vencimento de Parcela","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"tipoVencimentoParcela","name":"tipoVencimentoParcela","options":_vm.tipoVencimentoParcelaOpcoes},model:{value:(_vm.optionsLocal.tipoVencimentoParcela),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "tipoVencimentoParcela", $$v)},expression:"optionsLocal.tipoVencimentoParcela"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.optionsLocal.tipoVencimentoParcela == 'diaFixo')?_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Dia Fixo","rules":"required|min_value:1|max_value:28"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dia Fixo","label-for":"diaFixo"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"diaFixo","placeholder":"Dia Fixo","type":"number"},model:{value:(_vm.optionsLocal.diaFixoVencimentoParcela),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "diaFixoVencimentoParcela", _vm._n($$v))},expression:"optionsLocal.diaFixoVencimentoParcela"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,422374620)})],1):_vm._e(),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor Mínimo por Empréstimo","label-for":"valorMinimoEmprestimo"}},[_c('validation-provider',{attrs:{"name":"Valor Mínimo por Empréstimo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_vm._v("R$")])]),_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"valorMinimoEmprestimo","placeholder":"Valor Mínimo por Empréstimo"},model:{value:(_vm.minimoEmprestimo),callback:function ($$v) {_vm.minimoEmprestimo=$$v},expression:"minimoEmprestimo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor Mínimo por Parcela","label-for":"valorMinimoParcela"}},[_c('validation-provider',{attrs:{"name":"Valor Mínimo por Parcela","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_vm._v("R$")])]),_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"valorMinimoParcela","placeholder":"Valor Mínimo por Parcela"},model:{value:(_vm.minimoParcela),callback:function ($$v) {_vm.minimoParcela=$$v},expression:"minimoParcela"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Validade da Proposta em Dias","label-for":"validadePropostaDias"}},[_c('validation-provider',{attrs:{"name":"Validade da Proposta em Dias","rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"validadePropostaDias","state":errors.length > 0 ? false : null,"placeholder":"Validade da Proposta em Dias","type":"number"},model:{value:(_vm.optionsLocal.validadePropostaDias),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "validadePropostaDias", _vm._n($$v))},expression:"optionsLocal.validadePropostaDias"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(
              'O segurado poderá tomar um empréstimo somente após o período de carência a partir da sua data de admissão.'
            ),expression:"\n              'O segurado poderá tomar um empréstimo somente após o período de carência a partir da sua data de admissão.'\n            ",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Dias de carência","label-for":"diasDeCarencia"}},[_c('validation-provider',{attrs:{"name":"Dias de carência","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar,"id":"diasDeCarencia","state":errors.length > 0 ? false : null,"placeholder":"Dias de carência","type":"number"},model:{value:(_vm.optionsLocal.diasDeCarencia),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "diasDeCarencia", _vm._n($$v))},expression:"optionsLocal.diasDeCarencia"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset","disabled":!_vm.acesso.ConfiguracoesSistemaRestricaoEditar},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }